import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ViewShiftTable from '../../../../components/Exams/ViewShifts.table';
import AddShiftModal from '../../../../components/Modals/AddShift.modal';
import XcnButton from '../../../../components/XcnButton/XcnButton';
import ExamService from '../../../../services/exam.service';

export default function ExamShifts() {

    const params: any = useParams();

    const [shifts, setShifts] = useState<any[]>([]);
    const [showAddShiftModal, setShowAddShiftModal] = useState<boolean>(false);

    const getExamShifts = async() =>{
        await ExamService.getExamShifts(params.examId).then(res=>{
            if(res.status === 200){
                setShifts(res.data)
            }
        })
    }

    const deleteShift = async (shiftId: string) => {
        await ExamService.deleteShift(params.examId, shiftId).then(res=>{
            if(res.status === 200) {
                toast.warning("Shift Removed")
                getExamShifts()
            }
        })
    }
 
    useEffect(() => {
        getExamShifts();
    } ,[showAddShiftModal])

    return (
        <>
            <AddShiftModal 
                showAddShiftModal={showAddShiftModal}
                setShowAddShiftModal={setShowAddShiftModal}
            />
            <Container>
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="text-secondary fw-bold">
                            Exam Shifts
                        </h5>
                        <div>
                            <XcnButton 
                                variant="warning"
                                text={<div className="fw-bold">
                                    <FontAwesomeIcon icon={faPlus} className="me-1" /> 
                                    Add Shift
                                </div>}
                                onClick={()=>setShowAddShiftModal(true)}
                            />
                        </div>
                    </div>

                    <div>
                        <ViewShiftTable 
                            shifts={shifts}
                            deleteShift={deleteShift}
                        /> 

                    </div>

                </Card>
            </Container>
        </>
    )
}
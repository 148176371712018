import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileSize from 'filesize';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createDateFormat } from '../../helpers/time.helper';
import ExamService from '../../services/exam.service';

interface IViewJobsTable {
    reload?: boolean
}

export default function ViewJobsTable({reload} : IViewJobsTable){
    
    const navigate = useNavigate();
    const params: any = useParams();
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

    const getAllUploadedUserData = async () => {
        await ExamService.getAllUploadedUserData(params.examId).then(res => {
            if (res.status === 200) {
                setUploadedFiles(res.data);
            }
        })
    }

    useEffect(() => {
        getAllUploadedUserData();
    }, [reload])
    
    return (
        <>
            <Table borderless striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Job Id</th>
                                <th>File Name</th>
                                <th>Size</th>
                                <th>Created On</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                uploadedFiles.length > 0 ?
                                    uploadedFiles.map((data: any, index: number) => {
                                        return (
                                            <tr className="xcn-table-tr" key={data._id}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td className="fw-bold xcn-text-10">
                                                    {data._id}
                                                </td>
                                                <td className="fw-bold text-info xcn-text-12">
                                                    {data.originalName}
                                                </td>
                                                <td className="font-monospace fw-bold xcn-text-12">
                                                    {fileSize(data.size)}
                                                </td>
                                                <td className="fw-bold xcn-text-12 text-secondary">
                                                    {createDateFormat(data.createdAt)}
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon 
                                                        icon={faEye} 
                                                        className="text-info xcn-link-pointer" 
                                                        onClick={() => navigate("/examdash/" + params.examId + "/exam-mapping/map-job/"+data._id)} 
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr>
                                        <td colSpan={6} className="text-danger fw-bold xcn-text-12">
                                            No Added Files <br/>To Upload 
                                            <Link to={"/examdash/" +params.examId+ "/exam-uploads/user-data"} className='ms-2'>
                                                Click Here
                                            </Link>
                                        </td>
                                    </tr>
                            }

                        </tbody>
                    </Table>
        </>
    )
}
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import ViewCentersTable from "../../../components/Centers/ViewCenters.table";
import FiltersModal from "../../../components/Modals/Filters.modal";
import CenterService from "../../../services/center.service";
import { CSVDownload, CSVLink } from 'react-csv';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCogs, faDownload } from "@fortawesome/free-solid-svg-icons";


export default function ViewCenters() {

  const [allCenters, setAllCenters] = useState<any[]>([])
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [selectValue, setSelectValue] = useState<any>();
  const [search, setSearch] = useState<any>("");

  const [csvData, setCsvData] = useState<any>([]);

  const [downloadCsvFormat, setDownloadCsvFormat] = useState([]);


  const onChangeSelectValue = (e: any, name: any) => {
    setSelectValue({ ...selectValue, [name]: e })
  }




  const getAllCenter = async () => {
    await CenterService.searchCenter({
      state: selectValue ? selectValue.state?.value : undefined,
      corpObjId: selectValue ? selectValue.corpObjId?.value : undefined,
    }, search).then((res) => {
      if (res.status === 200) {
        console.log(res.data.centers)
        setAllCenters(res.data.centers);
        setShowFilterModal(false);
      }
    }).catch((err) => { toast.error('please Search By Center') })
  }


  const downloadCenterList = async () => {
    const centerIds = allCenters.map((data: any) => { return data._id });
    // console.log(centerIds, "ids")
    await CenterService.downloadCenterCsv({ centers: centerIds }).then((res) => {
      if (res.status === 200) {
        setCsvData(res.data.centers);
      }
    })
  }


  useEffect(() => {
    getAllCenter();
  }, [search])

  return (
    <>
      <Container className="xcn-conatiner">
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-primary fw-bold fs-5">
              Registered Centers
            </span>
            <div className="d-flex align-items-center justify-content-between">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search by center"
                  onChange={(e: any) => setSearch(e.target.value)}
                />
              </Form.Group>
              <div>
                <Button className="ms-3" size="sm" onClick={downloadCenterList} > Create CSV <FontAwesomeIcon icon={faCogs} /> </Button>
                {csvData && csvData.length > 0 &&
                  <CSVLink data={csvData} filename="center_list.csv" className="btn btn-secondary ms-2 btn-sm" >
                    Download CSV <FontAwesomeIcon icon={faDownload} />
                  </CSVLink>}
              </div>
              <div className="d-flex align-items-center ms-3">
                <Button size="sm" onClick={() => setShowFilterModal(true)}>Filters</Button>
              </div>
            </div>
          </div>
          <ViewCentersTable centers={allCenters} reload={getAllCenter} />
        </Card>
      </Container>

      <FiltersModal
        show={showFilterModal}
        setShow={setShowFilterModal}
        onChange={onChangeSelectValue}
        selectedData={selectValue}
        handleSubmission={getAllCenter}
      />
    </>
  );
}
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import ViewLabComputers from "../../../../components/Modals/ViewLabComputers";
import CenterService from "../../../../services/center.service";

interface IAllLabs {
  labsData: any
}


export default function AllLabs({ labsData }: IAllLabs) {
  const navigate = useNavigate();
  const params = useParams();

  const [selectedLab, setSelectedLab] = useState<any>([]);
  const [selectedLabId, setSelectedLabId] = useState<any>();
  const [show, setShow] = useState<boolean>(false);

console.log(labsData,"LD")
   
  return (
    <>
      <ViewLabComputers
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        data={selectedLab}
        selectedLabId={selectedLabId}
      />
      <Card className="xcn-card mt-4">
        <Table borderless striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Lab Name</th>
              <th>Building Name</th>
              <th>Number Of Nodes</th>
              <th>Center Id</th>
              <th>Last Estimation Date</th>
            </tr>
          </thead>
          <tbody>
            {labsData?.map((data: any, index: number) => (
              <tr className="xcn-table-tr">
                <td>{index + 1}</td>
                <td>{data.labName}</td>
                <td>{data.buildingName || "---"}</td>
                <td>{data.computerIds.length}</td>
                <td>{data.centerId}</td>
                <td>{moment(data.updatedAt).format("DD-MM-YYYY")}</td>

                <td>
                  <FontAwesomeIcon
                    icon={faEye}
                    className="text-info"
                    onClick={() => {
                      setSelectedLab(data);
                      setShow(true);
                      setSelectedLabId(data._id);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
}
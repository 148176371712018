import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Card, FloatingLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Auth from '../../Auth';
import AuthService from '../../services/auth.service';

export default function AdminLogin() {

    const [userName, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();

    let navigate = useNavigate();
    

    const adminLogin = async () => {
        if(!userName || !password){
            toast.error("All Fields are Required!")
            return
        }
        await AuthService.adminLogin(userName, password).then(res=>{
            if (res.status === 200) {
                sessionStorage.setItem("authToken", res.data.token);
                sessionStorage.setItem("userId", res.data.id);
                navigate('/dashboard/corporates/view');
            }
        }).catch(e => {
            toast.error(e.response.data);
            console.error(e);
        })
    }

      // const checkIfLoggedIn = async () => {
        //     // if () {
        //     //     navigate('/dashboard/home');
        //     // }
        //     // else {
        //     //     console.log("false return")
        //     //     Auth.clearAuthToken();
        //     // }
        // }

    useEffect(() => {
        if (Auth.checkAuth() === true) {
            navigate('/dashboard/corporates/view');
            // checkIfLoggedIn();
        }
    }, [])

    return (
        <>
            <div className="xcn-login-bg">

                <Container>
                    <h1 className="text-center text-white font-weight-bold xcn-login-header">
                        NIXCEN - Admin
                    </h1>

                    <Row className="justify-content-md-center  mt-4" >
                        <Col xs lg="2" />
                        <Col md="auto">
                            <Card className="p-3" style={{ maxWidth: '25rem', minWidth: '25vw' }}>

                                <>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email address"
                                        className="mb-3"
                                    >
                                        <Form.Control type="email" placeholder="name@example.com" onChange={(e:any)=> setUserName(e.target.value)} />
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingPassword" label="Password" onChange={(e:any)=> setPassword(e.target.value)}>
                                        <Form.Control type="password" placeholder="Password" />
                                    </FloatingLabel>
                                </>
                                <Button variant="primary" type="submit" className="mt-4" size="lg" onClick={adminLogin}>
                                    <span className='xcn-text-14'> 
                                        Login <FontAwesomeIcon icon={faLock}/>
                                    </span>
                                </Button>

                            </Card>


                        </Col>
                        <Col xs lg="2" />
                    </Row>


                </Container>
                <Container className="text-center">
                    <small className="text-center text-white">
                        &copy;2024 ENIXM - All Rights Reserved
                    </small>
                </Container>

            </div>
        </>
    )
}
import { faCheck, faChevronLeft, faChevronRight, faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import InputBox from '../../../../../components/Input/InputBox';
import XcnButton from '../../../../../components/XcnButton/XcnButton';
import MappingService from '../../../../../services/mapping.service';
import { useParams } from "react-router-dom";

interface IFinalizeAllotment {
    setSelectedStep: any
    selectedStep: number
}

interface ILogData {
    choice: string
    err: boolean
    msg: string
}

interface ILogStatus {
    err: boolean
    statuses: ILogData[]
}

export default function FinalizeAllotment(props: IFinalizeAllotment) {

    const params: any = useParams();

    const [surplus, setSurplus] = useState<number>(10);
    const [surplusLog, setSurplusLog] = useState<ILogStatus>();


    const checkifAllDistrictMappedToCenter = () => {
        MappingService.isAllDistrictMappedToCenter(params.jobId, surplus).then(res => {
            if (res.status === 200) setSurplusLog(res.data)
        })
    }

    const createLogEntry = (logData: ILogData) => {

        switch (logData.err) {
            case false: return (<div className="text-white">
                <span>
                    <FontAwesomeIcon icon={faCheck} className="text-neon-green ms-2" />
                </span> {logData.choice}: {logData.msg}
            </div>)

            case true: return (<div className="text-danger fw-bold">
                <span>
                    <FontAwesomeIcon icon={faTimes} className="text-danger ms-2 me-1" />
                </span> {logData.choice}: {logData.msg}
            </div>)
        }
    }

    return (
        <>
            <Container>
                <Card className="xcn-card mt-3">

                    <div className="d-flex justify-content-between align-items-center">
                        <XcnButton
                            text={<>
                                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                                Back
                            </>}
                            variant="danger"
                            onClick={() => props.setSelectedStep(props.selectedStep - 1)}
                        />

                        <XcnButton
                            text={<>
                                Next
                                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                            </>}
                            variant="success"
                            onClick={() => props.setSelectedStep(props.selectedStep + 1)}
                        />
                    </div>
                    <Row className="mt-4">
                        <Col md={4}>
                            <div>
                                <InputBox
                                    label="Required Surplus Nodes in % age"
                                    type="number"
                                    placeholder="Surplus Nodes in % age"
                                    onChange={(e: any) => setSurplus(e.target.value)}
                                    value={surplus}
                                    defaultValue={surplus}
                                />
                                <XcnButton
                                    text={<>
                                        Calculate Allotment
                                    </>}
                                    variant="primary"
                                    onClick={checkifAllDistrictMappedToCenter}
                                    size="sm"
                                    className="mt-2"
                                />
                            </div>
                        </Col>

                        <Col md={8}>
                            {
                                surplusLog && surplusLog.statuses.length > 0 ?
                                    <div className="bg-grey px-2 py-3 rounded">
                                        <div className="mb-3">
                                            {surplusLog.err ? <span className="text-warning ms-2">
                                                <FontAwesomeIcon icon={faWarning} className="me-1" />
                                                <span className="fw-bold">Failed: </span>Unable to map data suppied seats are less than available
                                            </span> : <span className="text-neon-green ms-2 fw-bold">
                                                <FontAwesomeIcon icon={faCheck} /> Passed: Success
                                            </span>}
                                        </div>
                                        {surplusLog.statuses.map((data: any, index: number) => {
                                            return (
                                                createLogEntry(data)
                                            )
                                        })}
                                    </div> : " "
                            }
                        </Col>

                    </Row>
                </Card>

            </Container>
        </>
    )
}
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Table, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AllLabs from "./AllLabs";
import AddLabModal from "../../../../components/Modals/AddLab.modal";
import CenterService from "../../../../services/center.service";

export default function ViewLabDetails() {
  const params: any = useParams();
  const [show, setShow] = useState(false);
  const [labsData, setLabsData] = useState<any>([]);


  const getCenterlabs = async () => {
    await CenterService.getCenterlabs(params.centerId).then((res: any) => {
      setLabsData(res.data.labData);
    })
  }

  useEffect(() => {
    getCenterlabs();
  }, []);

  return (
    <>
      <AddLabModal
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        corpId={params.corpId}
        reload={getCenterlabs}
      />
      <Container className="xcn-container">
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-primary fw-bold fs-5">Registered Labs</span>
            <span>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setShow(true)}
              >
                Add New Labs <FontAwesomeIcon icon={faPlus} />
              </Button>
            </span>
          </div>

          <AllLabs labsData={labsData} />
        </Card>
      </Container>
    </>
  );
}

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ViewExamTable from '../../components/Exams/ViewExams.table';
import XcnButton from '../../components/XcnButton/XcnButton';
import ExamService from '../../services/exam.service';

export default function ViewAllExams() {

    const [exams, setExams] = useState<any[]>([])

    const navigate = useNavigate();


    const getAllExams = async () => {
        await ExamService.getAllExams().then(res=>{
            if(res.status === 200){
                setExams(res.data)
            }
        })
    }

    useEffect(() => {
        getAllExams()
    }, [])

    return (
        <>

            <Container className="xcn-conatiner">
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <span className="text-primary fw-bold fs-5">
                            Exams
                        </span>
                        <XcnButton 
                            text={
                                <>
                                    <FontAwesomeIcon icon={faPlus} className="me-2"/>
                                    Add New Exam
                                </>
                            }
                            variant="secondary"
                            size="sm"
                            onClick={() =>navigate("/dashboard/exams/add-exam")}
                        />
                    </div>
                    <ViewExamTable
                        exams={exams}
                    />
                </Card>
            </Container>
        </>
    )
}
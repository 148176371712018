import React from 'react';

import { Routes, Outlet, Navigate, Route } from "react-router-dom";
import Auth from '../Auth';
import IRouter from '../interfaces/IRouter';


import Admin from '../views/Admin/Admin.index';
import CenterDash from '../views/CenterDash/CenterDash';
import Dashboard from '../views/Dashboard/Dashboard.index';
import ExamDash from '../views/ExamDash/ExamDash.index';
import AdminLogin from '../views/Login/AdminLogin';
import centerDashRoutes from './routes/centerDash/centerDash.routes';
import dashboardRoutes from './routes/dashboard.routes';
import examDashRoutes from './routes/examDash/examDash.routes';


function PrivateRouter ({children}: any) {
    const auth = Auth.checkAuth();//return true or false everytime
    return auth ? <Dashboard /> : <Navigate to="/login" />;// if user is already logged in then redirect to dashboard else redirect to login page
}


function CenterPrivateRouter ({children}: any) {
    const auth = Auth.checkAuth();
    return auth ? <CenterDash/> : <Navigate to="/login" />;// if user is already logged in then redirect to CenterDash else redirect to login page
}


function ExamPrivateRouter ({children}: any) {
    const auth = Auth.checkAuth();
    return auth ? <ExamDash/> : <Navigate to="/login" />;// if user is already logged in then redirect to ExamDash else redirect to login page
}

export default function MainRouter() {

    return (
        <>
            <Routes>
                {/*path is dashboard the render privateRounter */}
                <Route path="dashboard" element={<PrivateRouter/>}>
                    {dashboardRoutes.map((data:IRouter)=>{
                        // data will follow poperty of IRouter
                        return (
                            <Route
                            path={data.path + "/*"}
                            element={data.element}
                            
                            />
                        )
                    })}
                </Route>
                
                {/*path is centerdash/:centerId the render CenterPrivateRouter */}
                <Route path="centerdash/:centerId" element={<CenterPrivateRouter/>}>
                    {centerDashRoutes.map((data:IRouter)=>{
                        return (
                            <Route
                            path={data.path + "/*"}
                            element={data.element}
                            />
                        )
                    })}
                </Route>

                {/*path is examdash/:examId the render ExamPrivateRouter */}
                <Route path="examdash/:examId" element={<ExamPrivateRouter/>}>
                    {examDashRoutes.map((data:IRouter)=>{
                        return (
                            <Route
                            path={data.path + "/*"}
                            element={data.element}
                            />
                        )
                    })}
                </Route>

                {/* if path is login go for login*/}
                <Route path="login" element={<AdminLogin/>}/>

                {/* if path is * then go for /login using navigate component */}
                <Route path="*" element={<Navigate to ="/login"/>} />
            </Routes>
        </>
    )

}
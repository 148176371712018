import {
  faCheckCircle,
  faEdit,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CenterApprovals from "../../../../components/Centers/CenterApprovals";
import MyUploader from "../../../../components/Dropzone/dropzone";
import InputBox from "../../../../components/Input/InputBox";
import AddApprovalModal from "../../../../components/Modals/AddApproval.modal";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import CenterService from "../../../../services/center.service";
import { UploadService } from "../../../../services/upload.service";
import DatePicker from "react-datepicker"
import moment from "moment";
import Select from "react-select";



interface IPowerGenSet {
  generatorSize: string,
  mcbLoad: boolean,
  properWiring: boolean,
  fuelConsumePerHour: string,
  installedDate: any,
  powerBackup: string,
  genSetImage: string,
  rowPowerAvailability: string,
  dgAvailable: string,
  dgServiceDate: any,
  upsBackUp: string,
  upsServiceDate: string,
  upsPowerSupply: string,
  upsAvailable: string,
  dgPower: boolean
}

export default function PowerGenset() {
  const params: any = useParams();
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [powerGensetData, setPowerGensetData] = useState<any>();

  const [insatallationDate, setInsatallationDate] = useState<any>(new Date());
  const [serviceDate, setServiceData] = useState<any>(new Date());



  const fileSelectedHandler = async (e: any, name: string) => {
    const fd = new FormData();
    // let arr=[];
    for (let i of e) {
      fd.append("upload", i);
    }
    await UploadService.uploadFileToS3(fd).then((res: any) => {
      if (res.status === 200) {
        setPowerGensetData({ ...powerGensetData, [name]: res.data[0] });
        toast.success(`File(s) uploaded`);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleChange = (e: any) => {
    let newData: any;
    if (e.target.type === "checkbox") {
      newData = {
        ...powerGensetData,
        [e.target.name]: e.target.checked,
      };
    } else {
      newData = {
        ...powerGensetData,
        [e.target.name]: e.target.value,
      };
    }
    setPowerGensetData(newData);
  };

  const getCenterDetails = async () => {
    await CenterService.getCenterDetails(params.centerId)
      .then((res) => {
        setPowerGensetData(res?.data?.genSet);
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message}`
        );
      });
  };

  function removeEmpty(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
  }


  useEffect(() => {
    getCenterDetails();
  }, []);

  useEffect(() => {
  }, [powerGensetData]);

  const updateCenterData = async () => {
    await CenterService.updateCenterData(params.centerId, {
      genSet: removeEmpty(powerGensetData),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          setEditEnabled(false);
          getCenterDetails();
        }
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message}`
        );
      });
  };

  return (
    <>
      <Container>
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-secondary fw-bold">Power Genset</h5>
            <div>
              {editEnabled ? (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      Save
                    </>
                  }
                  variant="success"
                  onClick={updateCenterData}
                />
              ) : (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faEdit} className="me-2" />
                      Edit
                    </>
                  }
                  variant="warning"
                  onClick={() => setEditEnabled(true)}
                />
              )}
            </div>
          </div>
          {/* <Row>
            <Col md={6}>
              <InputBox
                type="number"
                name="generatorSize"
                label="Generator Size"
                defaultValue={String(powerGensetData?.generatorSize)}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="fuelConsumePerHour"
                label="Fuel consume per hour"
                disabled={!editEnabled}
                defaultValue={powerGensetData?.fuelConsumePerHour}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <InputBox
                type="text"
                name="upsAvailable"
                label="UPS available"
                disabled={!editEnabled}
                defaultValue={powerGensetData?.upsAvailable}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="powerBackup"
                label="Power Backup"
                disabled={!editEnabled}
                onChange={handleChange}
                defaultValue={powerGensetData?.powerBackup}

              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <InputBox
                type="text"
                name="rowPowerAvailability"
                label="Row power availability"
                disabled={!editEnabled}
                defaultValue={powerGensetData?.rowPowerAvailability}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="dgAvailable"
                label="DG availability"
                disabled={!editEnabled}
                defaultValue={powerGensetData?.dgAvailable}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">

            <Col md={6}>
              <InputBox
                type="text"
                name="upsPowerSupply"
                label="UPS power supply"
                disabled={!editEnabled}
                defaultValue={powerGensetData?.upsPowerSupply}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="upsBackUp"
                label="UPS backup"
                disabled={!editEnabled}
                defaultValue={powerGensetData?.upsBackUp}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">


            <Col md={6}>
              <Form.Group>
                <Form.Label className="text-muted">DG Service Date</Form.Label>
                <DatePicker
                  className="w-100 p-2"
                  selected={powerGensetData && powerGensetData.dgServiceDate ? moment(powerGensetData?.dgServiceDate).toDate() : serviceDate}
                  name="dgServiceDate"
                  onChange={(e: Date) => {
                    console.log(e, "date")
                    setServiceData(e)
                    setPowerGensetData({ ...powerGensetData, dgServiceDate: e });
                  }}
                  disabled={!editEnabled}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="text-muted">Installation Date</Form.Label>
                <DatePicker
                  className="w-100 p-2"
                  selected={powerGensetData && powerGensetData?.installedDate ? moment(powerGensetData?.installedDate).toDate() : insatallationDate}
                  name="installedDate"
                  onChange={(e: Date) => {
                    console.log(e, "date")
                    setInsatallationDate(e)
                    setPowerGensetData({ ...powerGensetData, installedDate: e });
                  }}
                  disabled={!editEnabled}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
            <span>
              <Form.Check
                inline
                type="switch"
                id="mcbLoad"
                label="Mcb Load"
                checked={powerGensetData?.mcbLoad}
                name="mcbLoad"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="properWiring"
                label="Proper Wiring"
                name="properWiring"
                checked={powerGensetData?.properWiring}
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="dgPower"
                label="DG Power"
                name="dgPower"
                checked={powerGensetData?.dgPower}
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
          </div> */}
          <Row>
            <Col md={6}>
              <InputBox
                type="number"
                name="generatorSize"
                label="DG Capacity (KvA)"
                defaultValue={powerGensetData?.generatorSize}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="fuelConsumePerHour"
                label="Fuel consume per hour"
                disabled={!editEnabled}
                defaultValue={powerGensetData?.fuelConsumePerHour}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <InputBox
                type="text"
                name="upsPowerSupply"
                label="UPS Capacity (in KVA)"
                disabled={!editEnabled}
                defaultValue={powerGensetData?.upsPowerSupply}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="upsBackUp"
                label="UPS backup Time (in minutes)"
                disabled={!editEnabled}
                defaultValue={powerGensetData?.upsBackUp}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <InputBox
                type="text"
                name="powerBackup"
                label="Power Backup"
                disabled={!editEnabled}
                onChange={handleChange}
                defaultValue={powerGensetData?.powerBackup}
              />
            </Col>
          </Row>


          <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label className="text-muted">DG Service Date</Form.Label>
                <DatePicker
                  className="w-100 p-2"
                  selected={powerGensetData && powerGensetData.dgServiceDate ? moment(powerGensetData?.dgServiceDate).toDate() : serviceDate}
                  name="dgServiceDate"
                  onChange={(e: Date) => {
                    console.log(e, "date")
                    setServiceData(e)
                    setPowerGensetData({ ...powerGensetData, dgServiceDate: e });
                  }}
                  disabled={!editEnabled}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="text-muted">UPS Service Date</Form.Label>
                <DatePicker
                  className="w-100 p-2"
                  selected={powerGensetData && powerGensetData?.installedDate ? moment(powerGensetData?.installedDate).toDate() : insatallationDate}
                  name="installedDate"
                  onChange={(e: Date) => {
                    console.log(e, "date")
                    setInsatallationDate(e)
                    setPowerGensetData({ ...powerGensetData, installedDate: e });
                  }}
                  disabled={!editEnabled}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
            <span>
              <Form.Check
                inline
                type="switch"
                id="mcbLoad"
                label="Mcb Load"
                checked={powerGensetData?.mcbLoad}
                name="mcbLoad"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="properWiring"
                label="Proper Wiring"
                name="properWiring"
                checked={powerGensetData?.properWiring}
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="dgPower"
                label="DG Power"
                name="dgPower"
                checked={powerGensetData?.dgPower}
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="rowPowerAvailability"
                label="Raw Power Availability"
                name="rowPowerAvailability"
                checked={powerGensetData?.rowPowerAvailability}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            {/* <span>
              <Form.Check
                inline
                type="switch"
                id="dgAvailable"
                label="DG Available"
                name="dgAvailable"
                checked={powerGensetData?.dgAvailable}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span> */}
            <span>
              <Form.Check
                inline
                type="switch"
                id="upsAvailable"
                label="UPS Available"
                name="upsAvailable"
                checked={powerGensetData?.upsAvailable}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>

                     </div>
            <Row>
              <Col md={4}>
            <Form.Label>
                UPS Type
              </Form.Label>
            <Select
                options={[
                  {
                    value: "Online",
                    label: "Online",
                  },{
                    value: "Offline",
                    label: "Offline",
                  }
                ]}
                value={{
                  value: powerGensetData?.upsType,
                  label: powerGensetData?.upsType
                }}
                placeholder="Category"
                className="mb-3"
                onChange={(e: any) => setPowerGensetData({ ...powerGensetData, upsType: e.value })}
                isDisabled={!editEnabled}
              />
              </Col>
            </Row>
          <Row></Row>
          <Row>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-4 text-secondary fw-bold w-100">
                <h6>
                  GenSet Image
                  {/* <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  /> */}
                </h6>
                {editEnabled === false && powerGensetData?.genSetImage ? <>
                  <Image src={powerGensetData?.genSetImage} width="100%" />
                </> :

                  <Row>
                    <Col md={6}>
                      <Image src={powerGensetData?.genSetImage} width="100%" />
                    </Col>
                    <Col md={6}>
                      <MyUploader
                        fileSelectedHandler={(e: any) =>
                          fileSelectedHandler(e, "genSetImage")
                        }
                      />
                    </Col>
                  </Row>}
              </div>
            </div>
          </Row>
        </Card>
      </Container>
    </>
  );
}

import React, { useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IAddCenter } from '../../interfaces/ICorpService.interface';
import { ISelectOption } from '../../interfaces/ISelectOption.interface';
import CommonService from '../../services/common.service';
import CorpService from '../../services/corp.service';
import Select from 'react-select'
import { addCenterValidation } from '../../validations/center.validations';

interface IAddCenterModal {
    show: boolean
    handleClose: any
    corpId: string,
    reload: any
}

export default function AddCenterModal(props: IAddCenterModal) {


    const [pincode, setPincode] = useState<string>();
    const [pincodeDetails, setPincodeDetails] = useState<any>();
    const [areaSelectOptions, setAreaSelectOptions] = useState<ISelectOption[]>([]);
    const [gstNumber, setgstNumber] = useState<string>()
    const [selectedArea, setSelectedArea] = useState<ISelectOption>();
    const [address, setAddress] = useState<string>();
    const [centerName, setCenterName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [contact, setContact] = useState<string>();



    const onAddNewCenterClick = async () => {
        const centerDetails: any = {
            corpId: props.corpId || "",
            pincode: pincode || "",
            stateId: pincodeDetails.stateId || "",
            areaId: selectedArea?.value || "",
            address: address || "",
            gstNumber: gstNumber || "",
            contact: contact || "",
            name: centerName || "",
            email: email || "",
            districtId: pincodeDetails._id || ""
        }
        const validateData = addCenterValidation.validate(centerDetails);
        if (validateData.error) {
            toast.error("Error: " + validateData.error)
            return false
        }
        await CorpService.addCenterToCorp(centerDetails).then(res => {
            if (res.status === 200) {
                toast.success("Center Added!")
                props.handleClose();
                props.reload();
            }
        }).catch(e => {
            toast.error("Error: " + e.response.data.message || e.response.data || "Something went wrong: Contact Admin");
        })
    }

    const getPincodeDetails = async () => {
        if (!pincode) {
            toast.error("Pincode is Mandatory");
            return false;
        }
        await CommonService.getPincodeInfo(pincode).then(res => {
            if (res.status === 200) {
                setPincodeDetails(res.data);
                const areaData: ISelectOption[] = res.data.area.map((data: any) => {
                    return {
                        label: data.officename,
                        value: data._id
                    }
                })
                setAreaSelectOptions(areaData);
            }
        })
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Register New Center</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h6 className="text-secondary fw-bold">
                            Pincode and Address Info
                        </h6>
                        <div className="d-flex align-items-center justify-content-start">
                            <div>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Pincode"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder="110057" onChange={(e) => setPincode(e.target.value)} />
                                </FloatingLabel>
                            </div>

                            <Button variant="primary" onClick={getPincodeDetails} className="ms-2">
                                Get Pincode Data
                            </Button>

                        </div>
                        <Select
                            options={areaSelectOptions}
                            placeholder="Select Area"
                            isDisabled={areaSelectOptions.length > 0 ? false : true}
                            onChange={(e: any) => setSelectedArea(e)}
                        />

                        <FloatingLabel
                            controlId="floatingInput"
                            label="State"
                            className="mt-3 mb-3"
                        >
                            <Form.Control type="text" onChange={(e) => setgstNumber(e.target.value)} defaultValue={pincodeDetails && pincodeDetails.state} disabled />
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="City"
                            className="mb-3"
                        >
                            <Form.Control type="text" onChange={(e) => setgstNumber(e.target.value)} defaultValue={pincodeDetails && pincodeDetails.district} disabled />
                        </FloatingLabel>


                        <div className="mt-2">
                            <FloatingLabel controlId="floatingTextarea2" label="Complete Address">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Complete Address"
                                    style={{ height: '100px' }}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                        <hr />
                        <h6 className="text-secondary fw-bold">
                            Other Center Data
                        </h6>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="GST Number"
                            className="mb-3"
                        >
                            <Form.Control type="text" onChange={(e) => setgstNumber(e.target.value)} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Center Name"
                            className="mb-3"
                        >
                            <Form.Control type="text" onChange={(e) => setCenterName(e.target.value)} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email Address"
                            className="mb-3"
                        >
                            <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Contact Number"
                            className="mb-3"
                        >
                            <Form.Control type="text" onChange={(e) => setContact(e.target.value)} />
                        </FloatingLabel>
                    </div>
                    <Button className="primary" onClick={onAddNewCenterClick}>
                        Add Center
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}
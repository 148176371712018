import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../interfaces/IRouter';
import AddExam from '../../views/Exams/AddExam';
import ViewAllExams from '../../views/Exams/ViewAllExams';

export const examBaseUrl = "/dashboard/exams/";

const examRoutes: IRouter[] = [
    {
        path: "view",
        element: <ViewAllExams/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "View All"
    },
    {
        path: "add-exam",
        element: <AddExam/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "Add New Exam"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }
    ]

export default examRoutes;
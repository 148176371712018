import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../../interfaces/IRouter';
import JobMappingExamMapping from '../../../views/ExamDash/ExamMapping/JobMapping/JobMapping.index';
import DataSelectExamMapping from '../../../views/ExamDash/ExamMapping/SelectData/DataSelect.examMapping';

export function examMappingBaseUrl(examId: string) {
    return "/exam-mapping/"+examId+"/"
}

const examMappingRoutes: IRouter[] = [
    {
        path: "select-data",
        element: <DataSelectExamMapping/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "Select Data"
    },
    {
        path: "map-job/:jobId",
        element: <JobMappingExamMapping/>,
        navbarShow: false,
        activeIcon: faHome,
        name: "Select Data"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }
    
    ]

export default examMappingRoutes;
import { faChevronLeft, faChevronRight, faLaptopCode, faTimesCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, Container, Form, Row, Col, Badge, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExamService from '../../../../../../services/exam.service';
import MappingService from '../../../../../../services/mapping.service';
import SelectedCenterDist from './SelectedCenterDist';
import SelectedCenterChoice from './SelectedCenterChoice';
import { ISelectedCenters } from "../../../../../../interfaces/ICenters.interface";
import XcnButton from '../../../../../../components/XcnButton/XcnButton';
import XcnModal from '../../../../../../components/Modals/XcnModal';

interface ISelectCenters {
    setSelectedStep: any
    selectedStep: number
}


export default function SelectCenters(props: ISelectCenters) {

    const params: any = useParams();
    const [fieldMapping, setFieldMapping] = useState<any>();

    const [currentSelectedDist, setCurrentSelectedDist] = useState<number>(0);

    const [requiredSeats, setrequiredSeats] = useState<number>(0);
    const [totalRequiredSeats, setTotalRequiredSeats] = useState<number>(0);

    const [bookedSeats, setBookedSeats] = useState<number>(0);

    const [allRequiredSeats, setAllRequiredSeats] = useState<any>();

    const [examShifts, setExamShifts] = useState<any[]>();

    const [showConfirm, setShowConfirm] = useState<boolean>(false);


    const getFieldMappingForJobId = async () => {
        await MappingService.getFieldMappingForJobId(params.jobId).then(res => {
            if (res.status === 200) {
                setFieldMapping(res.data);
            }
        })
    }

    const getExamShifts = async () => {
        await ExamService.getExamShifts(params.examId).then(res => {
            if (res.status === 200) {
                setExamShifts(res.data)
            }
        })
    }


    const showPrevious = () => {
        currentSelectedDist > 0 ? setCurrentSelectedDist(currentSelectedDist - 1) : toast.error("You are on the First District")

    }

    const showNext = () => {
        fieldMapping && fieldMapping.cityMapping && fieldMapping.cityMapping.length > currentSelectedDist ? setCurrentSelectedDist(currentSelectedDist + 1) : toast.error("You are on the Last District")
    }


    const getRequiredSeats = async () => {
        await MappingService.getRequiredSeats(params.jobId).then(res => {
            if (res.status === 200) {
                setAllRequiredSeats(res.data)
                const totalRequired: any = Object.values(res.data).reduce((a: any, b: any) => a + b)
                setTotalRequiredSeats(totalRequired);
            }
        })
    }

    const onNextClick = () => {
        props.setSelectedStep(props.selectedStep + 1)
    }

    useEffect(() => {
        Promise.all([
            getExamShifts(),
            getRequiredSeats(),
            getFieldMappingForJobId()
        ])
    }, [])

    useEffect(() => {
        if (fieldMapping && fieldMapping.cityMapping && fieldMapping.cityMapping.length > 0 && allRequiredSeats) {
            setrequiredSeats(allRequiredSeats[fieldMapping.cityMapping[currentSelectedDist].sheet])
        }
    }, [currentSelectedDist, getFieldMappingForJobId, getRequiredSeats])

    return (
        <>

            <XcnModal
                show={showConfirm}
                handleClose={() => setShowConfirm(false)}
                heading={
                    <span className="fs-5 fw-bold text-danger">
                        <FontAwesomeIcon icon={faWarning} className="me-1" /> Sure you want to Submit?
                    </span>
                }
                headerClassName="border-0"
                centered={true}
                body={
                    <div className="d-flex justify-content-end align-items-center">
                        <XcnButton
                            text={<> Close </>}
                            variant="secondary"
                            size="sm"
                            className="me-3"
                            onClick={() => setShowConfirm(false)}
                        />
                        <XcnButton
                            text={<> I Wish to Proceed </>}
                            variant="danger"
                            size="sm"
                            onClick={() => onNextClick()}
                        />
                    </div>
                }
            />

            <Container>
                <Card className="xcn-card mt-3">

                    <div className="d-flex justify-content-between align-items-center">
                        <XcnButton
                            text={<>
                                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                                Back
                            </>}
                            variant="danger"
                            onClick={() => props.setSelectedStep(props.selectedStep - 1)}
                        />
                        <div className="text-center">
                            <span className="text-muted fw-bold">
                                Required Seats:
                            </span>
                            <span className="text-danger ms-1 fw-bold">
                                {totalRequiredSeats}
                            </span>
                            <div className="fw-bold text-primary">


                                <span className="text-muted fw-bold me-1">
                                    Selected Choice:
                                </span>
                                {fieldMapping && fieldMapping.cityMapping && fieldMapping.cityMapping.length > 0 ? fieldMapping.cityMapping[currentSelectedDist].sheet : " Loading..."}

                            </div>
                        </div>

                        <XcnButton
                            text={<>
                                Submit & Next
                                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                            </>}
                            variant="success"
                            onClick={() => setShowConfirm(true)}
                        />
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3 fw-bold mt-4">
                        <div className="xcn-link-pointer"  >
                            <span className={currentSelectedDist <= 0 ? "d-none" : " "} onClick={showPrevious}>
                                <FontAwesomeIcon icon={faChevronLeft} /> Previous Choice
                            </span>
                        </div>
                        <div>
                        </div>
                        <div className="xcn-link-pointer">
                            <span
                                className={fieldMapping && fieldMapping.cityMapping && fieldMapping.cityMapping.length - 1 <= currentSelectedDist ? "d-none" : " "}
                                onClick={showNext}
                            >
                                Next Choice <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </div>
                    </div>
                    <hr />
                    <SelectedCenterChoice
                        fieldMapping={fieldMapping && fieldMapping.cityMapping && fieldMapping.cityMapping.length > currentSelectedDist ? fieldMapping.cityMapping[currentSelectedDist] : " "}
                        requiredSeats={requiredSeats}
                        shifts={examShifts}
                        key={currentSelectedDist}
                    />
                </Card>

            </Container>
        </>
    )
}
import makeRequest from "./makeRequest";
import urls from "./urls";

export default class PublicService{
    static async createCorpUser(name: string, email: string, password: string){
        return await makeRequest(urls.public.addCorpUser, "POST" ,{
            "orgName":name,
            "orgEmail":email,
            "password": password
        });
    }
}


import { faBuilding, faChalkboard, faChalkboardTeacher, faHome, faTimesCircle, faLocationPin, faChartLine } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../interfaces/IRouter';
import AdminIndex from "../../views/Admin/Admin.index";
import AdminStats from '../../views/Admin/AdminStats';
import CenterIndex from '../../views/Center/Center.index';
import CorpIndex from '../../views/Corporate/Corporate.index';
import ExamIndex from '../../views/Exams/Exam.index';

// here dashboardRoutes is follow Poperty of IRouter
const dashboardRoutes: IRouter[] = [
    // {
    //     path: "statistics",
    //     element: <AdminStats />,
    //     navbarShow: true,
    //     activeIcon: faChartLine,
    //     name: "Statistics"
    // },
    {
        path: "admin",
        element: <AdminIndex />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Admin"
    },
    {
        path: "corporates",
        element: <CorpIndex />,
        navbarShow: true,
        activeIcon: faBuilding,
        name: "Corporates"
    },
    {
        path: "centers",
        element: <CenterIndex />,
        navbarShow: true,
        activeIcon: faChalkboard,
        name: "Centers"
    },
    {
        path: "exams",
        element: <ExamIndex />,
        navbarShow: true,
        activeIcon: faChalkboardTeacher,
        name: "Exams"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }

]

export default dashboardRoutes;
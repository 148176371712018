import React, { useEffect, useState } from 'react'
import XcnModal from './XcnModal'

import Select from "react-select"
import CommonService from '../../services/common.service'
import { Button, Col, Form, Row } from 'react-bootstrap'

interface IFilterModal {
    show: any,
    setShow: any,
    onChange: any,
    selectedData: any,
    handleSubmission: any
}

const FiltersModal = ({ show, setShow, onChange, selectedData, handleSubmission }: IFilterModal) => {

    const [states, setState] = useState<any>();
    const [corps, setCorps] = useState<any>();


    const getStates = async () => {
        await CommonService.getStates().then(res => {
            if (res.status === 200) {
                setState(res.data.map((data: any) => {
                    return {
                        label: data.name,
                        value: data._id
                    }
                }))
            }
        })
    }

    const getAllCorps = async () => {
        await CommonService.getAllCorps().then((res) => {
            if (res.status === 200) {
                setCorps(res.data.corpId.map((corp: any) => {
                    return {
                        label: corp.orgName,
                        value: corp._id
                    }
                }))
            }
        })
    }

    useEffect(() => {
        getStates();
        getAllCorps()
    }, [])

    return (
        <>
            <XcnModal
                show={show}
                handleClose={() => setShow(false)}
                body={
                    <>
                        <Row>
                            <Col md={12}>
                                <div className="mt-2">
                                    <Form.Group>
                                        <Form.Label className="fw-bold text-muted">State</Form.Label>
                                        <Select
                                            options={states}
                                            onChange={(e: any) => onChange(e, "state")}
                                            value={selectedData ? selectedData.state : states && states[0]}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className='mt-2'>
                                    <Form.Group>
                                        <Form.Label className="fw-bold text-muted">Corp User</Form.Label>
                                        <Select
                                            options={corps}
                                            onChange={(e: any) => onChange(e, "corpObjId")}
                                            value={selectedData ? selectedData.corpObjId : corps && corps[0]}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        <Button size="sm" className="mt-3" onClick={handleSubmission}>Done</Button>
                    </>
                }
                heading="Filter"
            />
        </>
    )
}

export default FiltersModal
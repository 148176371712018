import React, { useEffect, useState } from "react";
import { Outlet, Routes } from "react-router-dom";
import SideBar from "../../components/Navbars/SideBar";
import centerDashRoutes from "../../router/routes/centerDash/centerDash.routes";
import examDashRoutes from "../../router/routes/examDash/examDash.routes";

export default function ExamDash() {

    const baseUrl = window.location.pathname.split("/").splice(0, 3).join("/");

    return (
        <>
            <SideBar
                routes={examDashRoutes}
                panelName="Exam"
                navUrlPos={3}
                baseUrl={baseUrl+"/"}
            />
            <Outlet />
        </>
    );
}

import Joi from 'joi';


export const addCenterValidation = Joi.object({
    corpId: Joi.string().required(),
    pincode: Joi.string().required(),
    stateId: Joi.string().required(),
    areaId: Joi.string().required(),
    address: Joi.string().required(),
    gstNumber: Joi.string().required(),
    contact: Joi.string().required(),
    name: Joi.string().required(),
    email: Joi.string().required(),
    districtId: Joi.string().required()
}).required()
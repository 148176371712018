import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../../interfaces/IRouter';
import ExamUserDataUpload from '../../../views/ExamDash/ExamUploads/UserData/ExamUserDataUpload';

export function examUploadsBaseUrl(examId: string) {
    return "/exam-uploads/"+examId+"/"
}

const examUploadRoutes: IRouter[] = [
    {
        path: "user-data",
        element: <ExamUserDataUpload/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "User Data"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }
    
    ]

export default examUploadRoutes;
import { ICityMappingData } from "../views/ExamDash/ExamMapping/JobMapping/MappingSteps/SelectCities";
import makeRequest from "./makeRequest";
import urls from "./urls";

export default class MappingService{
    
    static async updateCityMapping(jobId: string, cityMappingData: ICityMappingData[]){
        return await makeRequest(urls.mappings.updateCityMapping +"/"+ jobId, "PUT" , cityMappingData);
    }
    
    static async getFieldMappingForJobId(jobId: string){
        return await makeRequest(urls.mappings.getFieldMappingForJobId+"/"+jobId, "GET");
    }

    static async autoSelectCentersForDist(examId: string, distName: string, seats: number){
        return await makeRequest(urls.mappings.autoSelectCentersForDist+"/"+ examId, "POST", {
            distName: distName,
            seatsRequired: seats
        })
    }

    static async getDistCapacityByJobId(jobId: string, distName: string){
        return await makeRequest(urls.mappings.getDistCapacityByJobId+"/"+ jobId, "POST" , {
            centers: [distName]
        })
    }

    static async getCentersInDistByJobId(jobId: string, distName: string){
        return await makeRequest(urls.mappings.getAvailableCentersInDistByJobId+"/"+ jobId, "POST", {
            distName
        })
    }

    static async saveCenterSelectionDistrictShiftWise(jobId: string, examId: string, payload:any){
        return await makeRequest(urls.mappings.saveCenterSelectionDistrictShiftWise+"/"+ jobId + "/"+examId, "POST", payload)
    }

    static async getRequiredSeats(jobId:string) {
        return await makeRequest(urls.mappings.getRequiredSeatsByJobId+"/"+jobId, "GET")
    }
    static async getCenterData(jobId: string, payload: any) {
        return await makeRequest(urls.mappings.getCenterData+"/"+jobId, "POST", payload);
    }

    static async isAllDistrictMappedToCenter(jobId: string, surplus: number){
        return await makeRequest(urls.mappings.isAllDistrictMappedToCenter+"/"+ jobId, "POST", { surplus: Number(surplus) })
    }
}



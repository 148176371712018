import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CenterService from '../../services/center.service';
import InputBox from '../Input/InputBox';
import XcnButton from '../XcnButton/XcnButton';
import XcnModal from './XcnModal';

interface IAddBuildingPlan {
    showAddBuildingPlan: any
    setShowAddBuildingPlan: any,
    buildingId: string,
    centerId: string
}

export default function AddFloorToBuildingModal({
    showAddBuildingPlan,
    setShowAddBuildingPlan,
    buildingId,
    centerId,
}: IAddBuildingPlan) {

    
    const [name, setName] = useState<string>();
    const [number, setnumber] = useState<number>();


    const addFloorToBuilding = async () =>{
        await CenterService.addFloor(centerId, buildingId, { name, number }).then(res => {
            if(res.status === 200){
                toast.success("Floor Added")
                setShowAddBuildingPlan(false)
            }
        })
    }

    return (
        <>
            <XcnModal
                show={showAddBuildingPlan}
                handleClose={() => setShowAddBuildingPlan(false)}
                body={
                    <>
                        <InputBox
                            label="Name"
                            type="text"
                            onChange={(e: any) => setName(e.target.value)}
                        />
                        <InputBox
                            label="Number"
                            type="number"
                            onChange={(e: any) => setnumber(e.target.value)}
                            className='mt-3'
                        />

                        <XcnButton
                            variant="secondary"
                            text={
                                <>
                                    Add Floor
                                </>
                            }
                            size="sm"
                            className="mt-3"
                            onClick={addFloorToBuilding}
                        />
                    </>
                }
                heading="Add New Floor"
            />
        </>
    )
}
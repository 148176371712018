import React, { useEffect, useState } from "react";
import { faDownload, faFile, faFileUpload, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AllEmployee from "./AllEmployee";
import AddEmployeeModal from "../../../../components/Modals/AddEmployee.modal";
import CenterService from "../../../../services/center.service";
import UploadEmployeeCSV from "../../../../components/Modals/UploadEmployeeCSV.modal";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { toast } from "react-toastify";
import moment from "moment";
import UploadEmployeeZIP from "../../../../components/Modals/UploadEmployeeZIP.modal";
// import zip from 'jszip';

export default function ViewEmployeeDetails() {
  const params: any = useParams();
  const [show, setShow] = useState(false);
  const [csvShow, setCsvShow] = useState(false);
  const [zipShow, setZipShow] = useState(false);
  const [fetchEmployeeData, setFetchEmployeeData] = useState<any[]>([]);

  const [editEmployeeData, setEditEmployeeData] = useState<any>();

  const handleChangeValue = (e: any) => {
    setFetchEmployeeData({ ...fetchEmployeeData, [e.target.name]: e.target.value });
  }






  let csvOptions: any = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(csvOptions);


  const getAllEmployeeByCenter = async () => {
    await CenterService.getAllEmployeeByCenter(params.centerId).then((res: any) => {
      if (res.status === 200) {
        setFetchEmployeeData(res.data);
      }
    })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    getAllEmployeeByCenter();
  }, [show, csvShow, zipShow]);




  return (
    <>
      <AddEmployeeModal
        show={show}
        handleClose={() => {
          setShow(false);
          getAllEmployeeByCenter();
        }}
        corpId={params.corpId}
        reload={getAllEmployeeByCenter}
      />

      <UploadEmployeeCSV
        show={csvShow}
        handleClose={() => {
          setCsvShow(false);
        }}
        corpId={params.corpId}
      />

      <UploadEmployeeZIP
        show={zipShow}
        handleClose={() => {
          setZipShow(false);
        }}
        corpId={params.corpId}
      />


      <Container className="xcn-container">
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-primary fw-bold fs-5">All Employees</span>
            <span>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setShow(true)}
              >
                Add New Employee <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="ms-3"
                onClick={() => {
                  setCsvShow(true);
                }}
              >
                Upload CSV <FontAwesomeIcon icon={faFileUpload} />
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="ms-3"
                onClick={() => {
                  setZipShow(true);
                }}
              >
                Upload Zip <FontAwesomeIcon icon={faFileUpload} />
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                className="ms-3"
                onClick={() => {
                  if (fetchEmployeeData?.length > 1) {
                    csvExporter.generateCsv(fetchEmployeeData.map((data) => {
                      return {
                        "Worker Category": data.workerCategory && data.workerCategory.split("_").join(" ").toUpperCase(),
                        "Emp Id": data._id,
                        "Aadhar Number": data.aadhar,
                        "Father's Name": data.fatherName,
                        "Mother's Name": data.motherName,
                        "DOB": moment(data.dob).format("DD-MM-YYYY"),
                        "Name": data.employeeName,
                        "Id Card": data.idCard,
                        "Gender": data.gender,
                        "Type": data.typeOfEmployee,
                        "Blocked": data.blocked,
                        "Email": data.email,
                        "Address": data.address,
                        "Profile Image": data.image,
                        "Created On": moment(data.createdAt).format("DD-MM-YY hh:mm a"),
                        "Last Updated On": moment(data.updatedAt).format("DD-MM-YY hh:mm a")
                      }
                    }));
                  }
                  else {
                    toast.error("No Data to download")
                  }
                }}
              >
                Download CSV <FontAwesomeIcon icon={faFile} />
              </Button>
            </span>
          </div>

          <AllEmployee centerEmployeeData={fetchEmployeeData} reload={getAllEmployeeByCenter} />
          <hr />
         
        </Card>
      </Container>
    </>
  );
}




import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CenterService from '../../services/center.service';


interface IEditModal {
  show: any;
  handleClose: any;
  reload: any
}


const CenterStatusModal = ({ show, handleClose, reload }: IEditModal) => {


  const handleStatusCheck = async (id: string, status: any) => {
    const payload = { status: status };
    await CenterService.updateStatus(id, payload)
      .then((res) => {
        if (res.status === 200) {
          toast.success("updated successfully");
          handleClose(false);
          reload();
        }
      })
      .catch((err) => {
        toast.error("Unable to update");
      });
  };

  return (
    <Modal
      show={show ? true : false}
      onHide={() => handleClose(false)}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Active/Inactive</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="fw-bold me-2 mt-1">Status</Form.Label>
          <Form.Check
            type="switch"
            checked={show && show.isActive}
            onChange={(e: any) => {
              handleStatusCheck(show && show._id, e.target.checked);
            }}
          />
        </Form.Group>
      </Modal.Body>
    </Modal>
  );
};

export default CenterStatusModal

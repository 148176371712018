import makeRequest from "./makeRequest";
import makeUploadRequest from "./makeUploadRequest";
import urls from "./urls";

export default class ExamService{
    static async createExam(inputPayload?: any){
        return await makeRequest(urls.exam.createExam, "POST" , inputPayload);
    }
    static async getAllExams(){
        return await makeRequest(urls.exam.getAllExams, "GET");
    }
    static async getExamDetails(examId: string){
        return await makeRequest(urls.exam.getExamDetails +"/"+ examId, "GET")
    }
    static async getExamShifts(examId: string){
        return await makeRequest(urls.exam.getExamShifts +"/"+ examId, "GET")
    }

    static async deleteShift(examId: string, shiftId: string){
        return await makeRequest(urls.exam.deleteShift+"/"+ examId+"/"+shiftId, "DELETE")
    }
    
    static async addShift(examId: string, shiftName: string ,startTime: string, endTime: string){
        return await makeRequest(urls.exam.addShift+"/"+ examId, "PUT", {
            shiftName,
            startTime,
            endTime
        })
    }

    static async uploadUserData(examId: string, formData: any){
        return await makeUploadRequest(urls.exam.uploadUserData+"/"+examId, "POST", formData)
    }

    static async getAllUploadedUserData(examId: string){
        return await makeRequest(urls.exam.getAllUploadedUserData +"/"+ examId, "GET")
    }

    static async getSheetFields(jobId: string){
        return await makeRequest(urls.jobs.getSheetFields+"/"+jobId, "GET");
    }

    static async createFieldMapping(jobId: string, mappings: any[]){
        return await makeRequest(urls.jobs.createFieldMapping+"/"+jobId, "POST", {
            mappings: mappings
        })
    }

    static async getSheetDistrict(jobId: string){
        return await makeRequest(urls.jobs.getSheetDistrict +"/"+ jobId, "GET")
    }
}



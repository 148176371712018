import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CenterService from '../../services/center.service';
import InputBox from '../Input/InputBox';
import XcnButton from '../XcnButton/XcnButton';
import XcnModal from './XcnModal';

interface IViewBuildingPlan {
    showViewBuildingPlan: any
    setShowViewBuildingPlan: any,
    buildingId: string,
    centerId: string
    floorData: any
}

export default function ViewFloorModal({
    showViewBuildingPlan,
    setShowViewBuildingPlan,
    buildingId,
    centerId,
    floorData
}: IViewBuildingPlan) {


    const deleteFloor = async (floorId: string) => {
        await CenterService.removeFloorFromBuilding(centerId, buildingId, floorId).then(res => {
            if (res.status === 200) {
                toast.warning("Floor Removed")
                setShowViewBuildingPlan(false)
            }
        })
    }

    return (
        <>
            <XcnModal
                show={showViewBuildingPlan}
                handleClose={() => setShowViewBuildingPlan(false)}
                body={
                    <>
                        <Table borderless striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Floor No.</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {floorData && floorData.length > 0 ? floorData.map((data: any, index: number)=>{
                                    return (
                                        <tr className="xcn-table-tr" key={data._id}>
                                                <td >
                                                    {index+1}
                                                </td>
                                                <td className="fw-bold xcn-text-12">
                                                    {data.name}
                                                </td>
                                                <td>
                                                    {data.number}
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger xcn-link-pointer" onClick={()=>deleteFloor(data._id)}/>
                                                </td>
                                        </tr>
                                    )
                                }): "No Floors Available"}
                            </tbody>
                        </Table>
                        </>
                }
                        heading="View Floors"
            />
                    </>
    )
}
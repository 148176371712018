import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../interfaces/IRouter';
import ViewKeys from '../../views/Admin/APIKeys/ViewKeys';

export const adminBaseUrl = "/dashboard/admin/";


const adminRoutes: IRouter[] = [
    {
        path: "api-keys",
        element: <ViewKeys />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Api Keys"
    },
    // {
    //     path: "all-admin",
    //     element: <ViewKeys />,
    //     navbarShow: true,
    //     activeIcon: faHome,
    //     name: "Details"
    // },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }

]

export default adminRoutes;
import { faChartLine, faPersonBooth, faTimesCircle, faTools, faUpload } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../../interfaces/IRouter';
import AdminStats from '../../../views/Admin/AdminStats';
import ExamDashStats from '../../../views/ExamDash/ExamDashStats';
import ExamDetailsIndex from '../../../views/ExamDash/ExamDetails/ExamDetails.index';
import ExamMappingIndex from '../../../views/ExamDash/ExamMapping/ExamMapping.index';
import ExamUplaodsIndex from '../../../views/ExamDash/ExamUploads/ExamUploads.index';



const examDashRoutes: IRouter[] = [
    // {
    //     path: "statistics",
    //     element: <ExamDashStats />,
    //     navbarShow: true,
    //     activeIcon: faChartLine,
    //     name: "Statistics"
    // },
    {
        path: "exam-settings",
        element: <ExamDetailsIndex />,
        navbarShow: true,
        activeIcon: faTools,
        name: "Settings"
    },
    {
        path: "exam-uploads",
        element: <ExamUplaodsIndex />,
        navbarShow: true,
        activeIcon: faUpload,
        name: "Uploads"
    },
    {
        path: "exam-mapping",
        element: <ExamMappingIndex />,
        navbarShow: true,
        activeIcon: faPersonBooth,
        name: "Mapping"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }

]

export default examDashRoutes;
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap"
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputBox from '../../../../components/Input/InputBox'
import XcnButton from '../../../../components/XcnButton/XcnButton';
import CenterService from '../../../../services/center.service';
import CommonService from '../../../../services/common.service';


interface ILocation {
    onChange: any;
    updateCenterDetails: any;
    handlePincodeDetails: any,
    pincode: any
}


const Location = (props: ILocation) => {

    const params: any = useParams();

    const [editCenterDetails, setEditCenterDetails] = useState<boolean>(false);

    const [locationData, setLocationData] = useState<any>({});

    const handleInputChange = (e: any) => {
        setLocationData({ ...locationData, [e.target.name]: e.target.value });
    };

    // const requiredPincodeDetails = {
    //     pincode: pincodeDetails.pincode,
    //     district: pincodeDetails.district,
    //     stateId: pincodeDetails.stateId
    // }




    const getCenterLocation = async () => {
        await CenterService.getCenterLocation(params.centerId)
            .then((res) => {
                if (res.status === 200) {
                    setLocationData(res.data);
                }
            })
            .catch((error) => {
                toast.error(
                    error.response.data.message
                );
            });
    };

    useEffect(() => {
        getCenterLocation();
    }, []);

    const updateLocationData = async () => {
        delete locationData._id;
        delete locationData.centerId;
        delete locationData.createdAt;
        delete locationData.updatedAt;
        delete locationData.latitude;
        delete locationData.longitude;

        await CenterService.addLocation(params.centerId, locationData).then(
            (res) => {
                if (res.status === 200) {
                    toast.success("Updated Location Data");
                    setEditCenterDetails(false);
                    getCenterLocation();
                }
            }
        ).catch((error) => {
            toast.error(
                error.response.data.message
            );
        });
    }

    const editButtonKey = () => {
        setEditCenterDetails(!editCenterDetails);
    }

    return (
        <Card className="xcn-card mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="text-secondary fw-bold">Location</h5>
                <div>
                    {editCenterDetails ? (
                        <XcnButton
                            text={
                                <>
                                    <FontAwesomeIcon icon={faSave} className="me-2" />
                                    Save
                                </>
                            }
                            variant="success"
                            onClick={updateLocationData}
                        />
                    ) : (
                        <XcnButton
                            text={
                                <>
                                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                                    Edit
                                </>
                            }
                            variant="warning"
                            onClick={editButtonKey}
                        />
                    )}
                </div>
            </div>

            {/* <Row className="mt-3">
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearby Landmark"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearby"
                            defaultValue={locationData.nearby}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearest Bus stand"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearestbusstand"
                            defaultValue={locationData.nearestbusstand}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearest Airport"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearestairport"
                            defaultValue={locationData.nearestairport}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearest Railway"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearestrailway"
                            defaultValue={locationData.nearestrailway}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearest Metro station"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearestmetrostation"
                            defaultValue={locationData.nearestmetrostation}
                        />
                    </FloatingLabel>
                </Col>
            </Row> */}
            <Row className="mt-3">
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearby Landmark"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearby"
                            defaultValue={locationData.nearby}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearest Bus stand"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearestbusstand"
                            defaultValue={locationData.nearestbusstand}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearest Airport"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearestairport"
                            defaultValue={locationData.nearestairport}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearest Railway"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearestrailway"
                            defaultValue={locationData.nearestrailway}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Nearest Metro station"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="nearestmetrostation"
                            defaultValue={locationData.nearestmetrostation}
                        />
                    </FloatingLabel>
                </Col>
            </Row>

            {/* addedd */}

            <Row>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Distance from Bus Stand (KM)"
                        className="mb-3"
                    >
                        <Form.Control
                            type="number"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="distanceFromBusStand"
                            defaultValue={locationData.distanceFromBusStand}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Distance from Railway Station (KM)"
                        className="mb-3"
                    >
                        <Form.Control
                            type="number"
                            onChange={handleInputChange}
                            disabled={!editCenterDetails}
                            name="distanceFromRailwayStation"
                            defaultValue={locationData.distanceFromRailwayStation}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
        </Card>
    )
}

export default Location
import makeRequest from "./makeRequest";
import urls from "./urls";

export default class CommonService {
    static async getPincodeInfo(pincode: string) {
        return await makeRequest(urls.common.getPincodeInfo + "/" + pincode, "GET");
    }
    static async getStates() {
        return await makeRequest(urls.common.getStates, "GET");
    }
    static async getApiCountForGraph() {
        return await makeRequest(urls.getApiCountForGraph, "GET");
    }

    static async getDistrict(payload: any) {
        return await makeRequest(urls.common.getCityByName + "/" + payload, "GET");
    }

    static async getAllCorps() {
        return await makeRequest(urls.common.getAllCorps, "GET");
    }
}


import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputBox from "../../../../components/Input/InputBox";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import CenterService from "../../../../services/center.service";

interface ISurveillance {
  numberOfCamera: any;
  allLabCovered: boolean;
  dvrBrand: string;
  typeOfCamera: string;
  backupCapacity: string;

  totalNoOfSystem: number,
  dvrNvrAvailability: boolean,
  noOfWorkingCamerasAvailable: number,
  sizeOfTheDvrHardDisk: string,
  perCameraRecordingSizePerHour: string,
  howManyDaysRecordingCanStoreInDvrHdd: number,
  dvrRecordingWillBeHandedOverOnDayToDayBasic: boolean,
  howTheCenterWillGiveTheRecordingOnDailyBasis: string,
}

export default function Surveillance() {
  const params: any = useParams();

  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [surveillanceDetails, setSurveillanceDetails] =
    useState<ISurveillance>();

  const handleChange = (e: any) => {
    let newData: any;
    if (e.target.type === "checkbox") {
      newData = {
        ...surveillanceDetails,
        [e.target.name]: e.target.checked,
      };
    } else {
      newData = {
        ...surveillanceDetails,
        [e.target.name]: e.target.value,
      };
    }
    setSurveillanceDetails(newData);
  };

  function removeEmpty(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
  }

  const getCenterDetails = async () => {
    await CenterService.getCenterDetails(params.centerId)
      .then((res) => {
        setSurveillanceDetails(res.data.cctvInfo);
      });
  };

  useEffect(() => {
    getCenterDetails();
  }, []);

  useEffect(() => {
  }, [surveillanceDetails]);

  const updateCenterData = async () => {
    await CenterService.updateCenterData(params.centerId, {
      cctvInfo: removeEmpty(surveillanceDetails),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          setEditEnabled(false);
          getCenterDetails();
        }
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message}`
        );
      });
  };

  return (
    <>
      <Container>
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-secondary fw-bold">Surveillance</h5>
            <div>
              {editEnabled ? (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      Save
                    </>
                  }
                  variant="success"
                  onClick={updateCenterData}
                />
              ) : (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faEdit} className="me-2" />
                      Edit
                    </>
                  }
                  variant="warning"
                  onClick={() => setEditEnabled(true)}
                />
              )}
            </div>
          </div>
          {/* <Row>
            <Col md={6}>
              <InputBox
                type="number"
                name="numberOfCamera"
                label="No. of Camera Install"
                onChange={handleChange}
                disabled={!editEnabled}
                defaultValue={String(surveillanceDetails?.numberOfCamera)}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="typeOfCamera"
                onChange={handleChange}
                disabled={!editEnabled}
                label="Type of Camera"
                defaultValue={surveillanceDetails?.typeOfCamera}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <InputBox
                type="text"
                name="dvrBrand"
                onChange={handleChange}
                disabled={!editEnabled}
                label="DVR/NVR Model Name"
                defaultValue={surveillanceDetails?.dvrBrand}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="backupCapacity"
                onChange={handleChange}
                disabled={!editEnabled}
                label="Backup Capacity"
                defaultValue={surveillanceDetails?.backupCapacity}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 pt-3">
            <span>
              <Form.Check
                inline
                type="switch"
                id="mcbLoad"
                label="All lab covered"
                checked={surveillanceDetails?.allLabCovered}
                name="allLabCovered"
                disabled={!editEnabled}
                onChange={handleChange}

              />
            </span>
          </div> */}
          <Row>
            <Col md={6}>
              <InputBox
                type="number"
                name="totalNoOfSystem"
                label="Total No. of Systems under CCTV surveillance"
                defaultValue={surveillanceDetails?.totalNoOfSystem}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="number"
                name="numberOfCamera"
                label="No. of Camera Install"
                defaultValue={surveillanceDetails?.numberOfCamera}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3">

            <Col md={6}>
              <InputBox
                type="number"
                name="howManyDaysRecordingCanStoreInDvrHdd"
                label="How many days recording can store in DVR or HDD"
                defaultValue={surveillanceDetails?.howManyDaysRecordingCanStoreInDvrHdd}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="dvrBrand"
                label="DVR/NVR Model Name"
                defaultValue={surveillanceDetails?.dvrBrand}
                disabled={!editEnabled}
                onChange={handleChange}

              />
            </Col>
          </Row>
          <Row className="mt-3">

            <Col md={6}>
              <InputBox
                type="text"
                name="perCameraRecordingSizePerHour"
                label="Per Camera Recording Size Per Hour"
                defaultValue={surveillanceDetails?.perCameraRecordingSizePerHour}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="howTheCenterWillGiveTheRecordingOnDailyBasis"
                label="How the center will give the recording on daily basis"
                defaultValue={surveillanceDetails?.howTheCenterWillGiveTheRecordingOnDailyBasis}
                disabled={!editEnabled}
                onChange={handleChange}

              />
            </Col>
          </Row>

          {/* addedd end */}
          <Row className="mt-3">
            <Col>
              <InputBox
                type="text"
                name="backupCapacity"
                label="DVR/NVR Hard Disk Available"
                defaultValue={surveillanceDetails?.backupCapacity}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            {/* <Col md={6}>
              <InputBox
                type="text"
                name="dvrBrand"
                label="DVR/NVR Model Name"
                defaultValue={surveillanceDetails?.dvrBrand}
                disabled={!editEnabled}
                onChange={handleChange}

              />
            </Col> */}
          </Row>
          {/* <Row className="mt-3">
            <Col>
              <InputBox
                type="text"
                name="backupCapacity"
                label="DVR/NVR Hard Disk Available"
                defaultValue={surveillanceDetails?.backupCapacity}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
          </Row> */}
          <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
            <span>
              <Form.Check
                inline
                type="switch"
                id="allLabCovered"
                label="Coverage of all venue (No Blind spot)"
                checked={surveillanceDetails?.allLabCovered}
                name="allLabCovered"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>

            {/* added */}
            <span>
              <Form.Check
                inline
                type="switch"
                id="dvrNvrAvailability"
                label="DVR NVR Availability"
                checked={surveillanceDetails?.dvrNvrAvailability}
                name="dvrNvrAvailability"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="dvrRecordingWillBeHandedOverOnDayToDayBasic"
                label="DVR recording will be handed over on day to day basis"
                checked={surveillanceDetails?.dvrRecordingWillBeHandedOverOnDayToDayBasic}
                name="dvrRecordingWillBeHandedOverOnDayToDayBasic"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
          </div>
        </Card>
      </Container>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CenterApprovals from "../../../../components/Centers/CenterApprovals";
import InputBox from "../../../../components/Input/InputBox";
import AddApprovalModal from "../../../../components/Modals/AddApproval.modal";
import CenterService from "../../../../services/center.service";
import MyUploader from "../../../../components/Dropzone/dropzone";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

interface IPhysicalArchitecture {
  ExmaCenterType: string;
  totalGateEntries: string;
  oneGateUsedForEntry: boolean;
  totalLabs: string;
  isMultiTenant: boolean;
  residentailsFlatsAvailable: boolean;
  entryToExamPremiseAvailable: boolean;
  accessiblityOfExamBuildingFromOtherBuilding: boolean;
  restroomInLab: boolean;
  surveillanceOfRestroomPath: boolean;
  falseDoorInRestroom: boolean;
  serverSeparatedFromExamLab: boolean;
  blindSpotInLab: boolean;
  StorageAreaForCandidateBags: boolean;
  sufficientGapInNodes: boolean;
  heightPartitionBetweenNdes: boolean;
  surveillanceOfVM: boolean;
  coachingInstitutionNearExamBuilding: boolean;
}

export default function PhysicalArchitecture() {
  const params: any = useParams();

  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [physicalArchitectureData, setPhysicalArchitectureData] =
    useState<IPhysicalArchitecture>({
      ExmaCenterType: "",
      totalGateEntries: "",
      oneGateUsedForEntry: false,
      totalLabs: "",
      isMultiTenant: false,
      residentailsFlatsAvailable: false,
      entryToExamPremiseAvailable: false,
      accessiblityOfExamBuildingFromOtherBuilding: false,
      restroomInLab: false,
      surveillanceOfRestroomPath: false,
      falseDoorInRestroom: false,
      serverSeparatedFromExamLab: false,
      blindSpotInLab: false,
      StorageAreaForCandidateBags: false,
      sufficientGapInNodes: false,
      heightPartitionBetweenNdes: false,
      surveillanceOfVM: false,
      coachingInstitutionNearExamBuilding: false,
    });

  const handleChange = (e: any) => {
    let newData: any;
    if (e.target.type === "checkbox") {
      newData = {
        ...physicalArchitectureData,
        [e.target.name]: e.target.checked,
      };
    } else {
      newData = {
        ...physicalArchitectureData,
        [e.target.name]: e.target.value,
      };
    }
    setPhysicalArchitectureData(newData);
  };

  function removeEmpty(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
  }

  const getCenterDetails = async () => {
    await CenterService.getCenterDetails(params.centerId)
      .then((res) => {
        console.log(res.data, "physical archi");
        setPhysicalArchitectureData(res.data.physicalArchitecture)
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message}`
        );
      });
  };

  useEffect(() => {
    getCenterDetails();
  }, []);

  useEffect(() => {
    console.log(physicalArchitectureData);
  }, [physicalArchitectureData]);

  const updateCenterData = async () => {
    await CenterService.updateCenterData(params.centerId, {
      physicalArchitecture: removeEmpty(physicalArchitectureData),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          setEditEnabled(false);
          getCenterDetails();
        }
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message}`
        );
      });
  };

  return (
    <>
      <Container>
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-secondary fw-bold">Physical Architecture</h5>
            <div>
              {editEnabled ? (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      Save
                    </>
                  }
                  variant="success"
                  onClick={updateCenterData}
                />
              ) : (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faEdit} className="me-2" />
                      Edit
                    </>
                  }
                  variant="warning"
                  onClick={() => setEditEnabled(true)}
                />
              )}
            </div>
          </div>
          <div className="p-4">
            <Row>
              <Col md={6}>
                <InputBox
                  type="text"
                  name="ExmaCenterType"
                  label="Exam Center Type: College/School/Training Institute/Test Center"
                  disabled={!editEnabled}
                  onChange={handleChange}
                  defaultValue={physicalArchitectureData?.ExmaCenterType}
                />
              </Col>
              <Col md={6}>
                <InputBox
                  type="number"
                  name="totalGateEntries"
                  label="Total number of entry/exit gates available at the center"
                  disabled={!editEnabled}
                  onChange={handleChange}
                  defaultValue={String(physicalArchitectureData?.totalGateEntries)}

                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <InputBox
                  type="number"
                  name="totalLabs"
                  label="Total Number of exam Lab/Floor"
                  disabled={!editEnabled}
                  onChange={handleChange}
                  defaultValue={String(physicalArchitectureData?.totalLabs)}
                />
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
            <span>
              <Form.Check
                inline
                type="switch"
                id="oneGateUsedForEntry"
                label="Only one gate used for entry/exit"
                checked={physicalArchitectureData?.oneGateUsedForEntry}
                name="oneGateUsedForEntry"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="isMultiTenant"
                label="Is the exam building multi-tenant"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.isMultiTenant}

                name="isMultiTenant"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="residentailsFlatsAvailable"
                label="Any residential flats available in exam building?"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.residentailsFlatsAvailable}

                name="residentailsFlatsAvailable"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="entryToExamPremiseAvailable"
                label="Dedicated entry to exam premise available"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.entryToExamPremiseAvailable}

                name="entryToExamPremiseAvailable"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="accessiblityOfExamBuildingFromOtherBuilding"
                label="Exam building accessibility with adjacent non exam or residential buildings"
                checked={physicalArchitectureData?.accessiblityOfExamBuildingFromOtherBuilding}

                // checked={props.updateCenterFacilities?.drinkingWater}
                name="accessiblityOfExamBuildingFromOtherBuilding"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="restroomInLab"
                label="Are washroom outside the lab"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.restroomInLab}

                name="restroomInLab"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="surveillanceOfRestroomPath"
                label="Washroom pathway under surveillance"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.surveillanceOfRestroomPath}

                name="surveillanceOfRestroomPath"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="blindSpotInLab"
                label="Blind spots in lab"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.blindSpotInLab}

                name="blindSpotInLab"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="falseDoorInRestroom"
                label="extra door in washroom leading out of the center, into another lab or empty room"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.falseDoorInRestroom}

                name="falseDoorInRestroom"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="StorageAreaForCandidateBags"
                label="Separate area for candidate belongings"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.StorageAreaForCandidateBags}

                name="StorageAreaForCandidateBags"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="serverSeparatedFromExamLab"
                label="Physical separation between exam labs, server room and area from other environment"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.serverSeparatedFromExamLab}
                name="serverSeparatedFromExamLab"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="sufficientGapInNodes"
                label="Sufficient gap between nodes"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.sufficientGapInNodes}

                name="sufficientGapInNodes"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="heightPartitionBetweenNdes"
                label="Sufficient height of physical partition between nodes"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.heightPartitionBetweenNdes}
                name="heightPartitionBetweenNdes"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="surveillanceOfVM"
                label="VM/Director room to be checked thoroughly and under surveillance"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={physicalArchitectureData?.surveillanceOfVM}

                name="surveillanceOfVM"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="coachingInstitutionNearExamBuilding"
                label="Coaching institute available in or near the building"
                checked={physicalArchitectureData?.coachingInstitutionNearExamBuilding}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="coachingInstitutionNearExamBuilding"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>
          </div>
        </Card>
      </Container>
    </>
  );
}

import React from 'react';
import { Container } from 'react-bootstrap';

interface ISelectMapping {
    component: any
    setSelectedStep: any
    selectedStep: number
}

export default function SelectMapping(props: ISelectMapping) {
    return (
        <props.component setSelectedStep={props.setSelectedStep} selectedStep={props.selectedStep}/>
    )
}
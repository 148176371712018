import { faChartLine, faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../interfaces/IRouter';
import AdminStats from '../../views/Admin/AdminStats';
import ViewCenters from '../../views/Center/ViewCenter/ViewCenters';

export const centerBaseUrl = "/dashboard/centers/";

const centerRoutes: IRouter[] = [
    {
        path: "view",
        element: <ViewCenters/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "View All"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }
    ]

export default centerRoutes;
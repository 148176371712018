import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, Container, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ViewShiftTable from '../../../../../components/Exams/ViewShifts.table';
import XcnButton from '../../../../../components/XcnButton/XcnButton';
import ExamService from '../../../../../services/exam.service';


interface IShiftsMapping {
    setSelectedStep: any
    selectedStep: number
}


export default function ShiftsMapping(props: IShiftsMapping) {

    const params: any = useParams();

    const [shifts, setShifts] = useState<any>([]);

    const getExamShifts = async () => {
        await ExamService.getExamShifts(params.examId).then(res => {
            if (res.status === 200) {
                setShifts(res.data);
            }
        })
    }

    useEffect(() => {
        getExamShifts();
    }, [])


    return (
        <>
            <Container>
                <Card className="xcn-card mt-3">
                    <h5 className="text-secondary fw-bold">
                        Verify Shifts Data
                    </h5>
                    <div className="mt-3">
                        <ViewShiftTable
                            shifts={shifts}
                            deleteShift={() => toast.warning("Not Allowed to Remove Shifts During Mapping")}
                        />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <XcnButton
                            text={<>
                                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                                Back
                            </>}
                            variant="danger"
                            onClick={() => props.setSelectedStep(props.selectedStep - 1)}
                        />
                        <XcnButton
                            text={<>
                                Save and Next
                                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                            </>}
                            variant="success"
                            onClick={() => props.setSelectedStep(props.selectedStep + 1)}
                            disabled={shifts.length > 0 ? false : true}
                        />
                    </div>
                </Card>
            </Container>
        </>
    )
}
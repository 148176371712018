import React, { useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserDataDropZone from '../../../../components/Dropzone/UserData.dropzone';
import ViewJobsTable from '../../../../components/Exams/ViewJobs.table';
import ExamService from '../../../../services/exam.service';

export default function ExamUserDataUpload(){

    const params: any = useParams();
    const [reload, setReload] = useState<boolean>(false);

    const handleUploadedFile = async(files: any) =>{
        let formData = new FormData();
        formData.append("userData", files[0]);

        await ExamService.uploadUserData(params.examId, formData).then(res=>{
            if(res.status === 200){
                toast.success("Candidate Data Uploaded")
            }
        }).catch(e=>{
            console.error(e)
            toast.error(e.response.data || e.response.data.msg)
        })
        setReload(!reload);
    }

    return (
        <>
            <Container>
                <div className="mt-3">
                    <UserDataDropZone
                        handleUploadedFile={handleUploadedFile}
                    />
                </div>
                <Card className="xcn-card mt-3">
                    <h5 className="text-secondary fw-bold mb-3">
                        Past Uploaded Files for exam
                    </h5>
                    <ViewJobsTable reload={reload}/>
                </Card>
            </Container>
        </>
    )
}
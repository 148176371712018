import React, { useState } from 'react'
import { Button, Card, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import InputBox from '../Input/InputBox'
import XcnButton from '../XcnButton/XcnButton'
import XcnModal from './XcnModal'
import DateTime from "react-datetime";
import DatePicker from "react-datepicker"
import CenterService from '../../services/center.service'

import { toast } from 'react-toastify'
import MyUploader from '../Dropzone/dropzone'
import { AnyAaaaRecord } from 'dns'
import { UploadService } from '../../services/upload.service'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


interface IAddApprovals {
    show: any,
    setShow: any,
    centerId: any,
    reload?: any
}

const AddApprovalModal = ({ show, setShow, centerId, reload }: IAddApprovals) => {

    const [approvalValue, setApprovalValue] = useState<any>()
    const [approvedOn, setApprovedOn] = useState<any>(new Date());
    const [approvalValidUpto, setApprovalValidUpto] = useState<any>(new Date());
    const [visited, setVisited] = useState<boolean>(false);



    const [auditUpload, setAuditUpload] = useState<any>();

    const handleInputChange = (e: any) => {
        setApprovalValue({ ...approvalValue, [e.target.name]: e.target.value })
    }



    const handleApprovalSubmission = async () => {

        let formData: any = new FormData;

        formData.append("upload", auditUpload)
        formData.append("visited", visited)
        formData.append("nodesAvailable", approvalValue.nodesAvailable)
        formData.append("nodesApproved", approvalValue.nodesApproved)
        formData.append("numberofLabsApproved", approvalValue.numberOfLabsApproved)
        formData.append("approvedOn", approvedOn.toISOString())
        formData.append("approvalValidUpto", approvalValidUpto.toISOString())
        formData.append("remarks", approvalValue.remarks)

        toast.promise(
            CenterService.createApprovalsByCenterId(centerId, formData).then((res) => {
                if (res.status === 200) {
                    setShow(false);
                    reload();
                }
            }), {
            success: "Approval create successfully",
            error: "Error while uploading",
            pending: "Loading..."
        })
    }

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Approved node"
                            className="mb-3"
                        >
                            <Form.Control type="text" name="nodesApproved" onChange={handleInputChange} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Available Nodes"
                            className="mb-3"
                        >
                            <Form.Control type="text" name="nodesAvailable" onChange={handleInputChange} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="No. of Labs"
                            className="mb-3"
                        >
                            <Form.Control type="text" name="numberOfLabsApproved" onChange={handleInputChange} />
                        </FloatingLabel>
                        <div className="border mb-3 text-muted">
                            <Form.Group className="d-flex align-items-center ms-2 p-2">
                                <Form.Label>Visited</Form.Label>
                                <Form.Check className="ms-2 mb-2" type="switch" checked={visited} onChange={(e: any) => { setVisited(e.target.checked) }} />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label className="fw-bold xcn-text-12 text-secondary mb-3">Upload Center Audit
                                    {auditUpload && <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />}
                                </Form.Label>
                                <MyUploader fileSelectedHandler={(e: any) => setAuditUpload(e[0])} />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label className="fw-bold xcn-text-12 text-secondary mb-3"> Center Remarks</Form.Label>
                                <Form.Control as="textarea" style={{ height: '80px' }} name="remarks" onChange={handleInputChange} />
                            </Form.Group>
                        </div>
                        <div className="mt-3">
                            <Form.Label className="fw-bold xcn-text-12 text-secondary">
                                Approved Start Time - Approved End Time
                            </Form.Label>
                            <div className="d-flex align-items-center">
                                {/* <DateTime onChange={(e: any) => handleDateChange('startTime', e)} /> */}
                                <DatePicker className="form form-control" selected={approvedOn} name="approvedOn" onChange={(e: Date) => setApprovedOn(e)} />
                                <div className="fw-bold mx-3"> - </div>
                                {/* <DateTime onChange={(e: any) => handleDateChange('endTime', e)} /> */}
                                <DatePicker className="form form-control" selected={approvalValidUpto} name="approvalValidUpto" onChange={(date: Date) => setApprovalValidUpto(date)} />

                            </div>
                        </div>
                    </div>
                    <Button className="primary mt-3" onClick={handleApprovalSubmission}>Submit</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddApprovalModal
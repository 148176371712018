import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, Container, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createDateFormat } from '../../../../helpers/time.helper';
import ExamService from '../../../../services/exam.service';
import fileSize from 'filesize';
import ViewJobsTable from '../../../../components/Exams/ViewJobs.table';

export default function DataSelectExamMapping() {

    return (
        <>
            <Container>
                <Card className="xcn-card mt-3">
                    <ViewJobsTable />
                </Card>
            </Container>
        </>
    )
}
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Table } from 'react-bootstrap';
import { createDateFormatWithTime } from '../../helpers/time.helper';

interface IViewShiftTable{
    shifts: any[],
    deleteShift: any
}

export default function ViewShiftTable(props: IViewShiftTable) {
    return (
        <div>
            <Table borderless striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Shift Id</th>
                        <th>Shift Name</th>
                        <th>Shift Time</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.shifts.length > 0 ?
                            props.shifts.map((data: any, index: number) => {
                                return (
                                    <tr className="xcn-table-tr" key={data._id}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td className="fw-bold xcn-text-12 text-secondary font-monospace">
                                            {data._id}
                                        </td>
                                        <td className="fw-bold xcn-text-14 text-secondary">
                                            {data.shiftName}
                                        </td>
                                        <td className="fw-bold xcn-text-12 text-secondary">
                                            {createDateFormatWithTime(data.startTime) +" - "+ createDateFormatWithTime(data.endTime)}
                                        </td>
                                        <td>
                                            <FontAwesomeIcon icon={faTimesCircle} 
                                                className="text-danger xcn-link-pointer xcn-text-18" 
                                                onClick={()=> props.deleteShift(data._id)}
                                                />
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={6} className="text-danger fw-bold">
                                    No Shifts Available
                                </td>
                            </tr>
                    }

                </tbody>
            </Table>
        </div>
    )
}
import { METHODS } from "http";
import makeRequest from "./makeRequest";
import urls from "./urls";

export class AdminService {
    static async createApiKey(url:string, center_id?: string, corp_id?: string) {
        return await makeRequest(url, "POST", {
            center: center_id,
            corpUser: corp_id
        });
    }

    static async getAllApiKeys() {
        return await makeRequest(urls.admin.getAllApiKeys, 'GET');
    }

    static async toggleApiKeyStatus(id: string, status: boolean) {
        return await makeRequest(urls.admin.toggleApiKeyStatus + "/" + id, 'PUT', {
            status: status
        });
    }
    
    static async deleteKey(id:string){
        return await makeRequest(urls.admin.deleteKey + "/" +id, 'DELETE', {});
    }
}
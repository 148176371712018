import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import TopBar from '../../../components/Navbars/TopBar';
import XcnComponent from '../../../components/XcnComponent/XcnComponent';
import examUploadRoutes, { examUploadsBaseUrl } from '../../../router/routes/examDash/examUploads.routes';

export default function ExamUplaodsIndex(){
    const params: any = useParams();
    
    return (
        <>
          <TopBar
            routes={examUploadRoutes}
            baseUrl={examUploadsBaseUrl(params.examId)}
            navUrlPos={3}
          />

          <XcnComponent 
            routes={examUploadRoutes}
            baseUrl={examUploadsBaseUrl(params.examId)}
            navUrlPos={3}
          />
            <Outlet/>
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { faEye, faInfoCircle, faPlus, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AddFloorToBuildingModal from '../Modals/AddFloor.modal';
import InfoModal from '../Modals/BuildingInfo.modal'
import ViewFloorModal from '../Modals/ViewFloor.modal';
import BooleanStatus from '../status/BooleanStatus';
import CenterService from '../../services/center.service'
import { toast } from 'react-toastify';

interface ICenterBuildingsTable {
    buildings: any[]
    refresh: any
    onSendData: any
}

export default function CenterBuildingsTable(props: ICenterBuildingsTable) {
    const [showAddFloor, setShowAddFloor] = useState<boolean>(false);
    const [showViewFloor, setShowViewFloor] = useState<boolean>(false);
    const [showBuildingInfo, setShowBuildingInfo] = useState<boolean>(false)

    const [selectedBuilding, setSelectedBuilding] = useState<string>("");
    const [selectedFloorData, setSelectedFloorData] = useState<any>({});

    const params: any = useParams();


    const removeCenterBuilding = async(id: any) => {
        await CenterService.removeBuilding(params.centerId, id).then((res) => {
            if(res.status === 200) {
                props.onSendData();
                toast.success("Deleted Successfully");
            }
        })
        .catch((err) => {
            toast.error("Error", err.response.status);
        })
    }

    useEffect(() => {
        props.refresh(String(Math.random()))
    }, [showAddFloor, showViewFloor])

    return (
        <>
            <AddFloorToBuildingModal
                showAddBuildingPlan={showAddFloor}
                setShowAddBuildingPlan={setShowAddFloor}
                buildingId={selectedBuilding}
                centerId={params.centerId}
            />

            <ViewFloorModal
                showViewBuildingPlan={showViewFloor}
                setShowViewBuildingPlan={setShowViewFloor}
                buildingId={selectedBuilding}
                centerId={params.centerId}
                floorData={selectedFloorData}
            />

            <Table borderless striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Floors</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.buildings && props.buildings.length > 0 ?
                            props.buildings.map((data: any, index: number) => {
                                console.log(data);
                                return (
                                    <tr className="xcn-table-tr" key={data._id}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td className="fw-bold xcn-text-12">
                                            {data.name}
                                        </td>
                                        <td className="font-monospace xcn-text-12">
                                            {data.location}
                                        </td>
                                        <td className="fw-bold text-secondary">
                                            {data.floors.length}
                                            <FontAwesomeIcon icon={faPlusCircle} className="text-primary ms-2 xcn-link-pointer" onClick={() => {
                                                setSelectedBuilding(data._id)
                                                setShowAddFloor(true)
                                            }} />
                                            <FontAwesomeIcon icon={faEye} className="ms-1 text-info xcn-link-pointer" onClick={() => {
                                                setSelectedBuilding(data._id)
                                                setSelectedFloorData(data.floors)
                                                setShowViewFloor(true)
                                            }}/>

                                        </td>
                                        <td >
                                            <FontAwesomeIcon icon={faInfoCircle} className="text-warning xcn-link-pointer" onClick={() => setShowBuildingInfo(true)}/>
                                                <InfoModal buildingInfo = {showBuildingInfo} setBuildingInfo = {setShowBuildingInfo} sendInfo = {data.otherInfo}/>
                                            <FontAwesomeIcon icon={faTimesCircle} className="text-danger ms-2 xcn-link-pointer" onClick = {() => removeCenterBuilding(data._id)} />
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={5} className="text-danger fw-bold">
                                    No Buildings Registered
                                </td>
                            </tr>
                    }

                </tbody>
            </Table>
        </>
    )
}
import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '../../components/Navbars/TopBar';
import XcnComponent from '../../components/XcnComponent/XcnComponent';
import examRoutes, { examBaseUrl } from '../../router/routes/exam.routes';

export default function ExamIndex(){
    return (
        <>
            <TopBar
                routes = {examRoutes}
                baseUrl={examBaseUrl}
                navUrlPos={3}
            />
            <XcnComponent 
                routes={examRoutes}
                baseUrl={examBaseUrl}
                navUrlPos={3}
            />
            <Outlet/>
        </>
    )
}
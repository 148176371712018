import React, { useState } from 'react';
import { handleInputChange } from 'react-select/dist/declarations/src/utils';
import InputBox from '../Input/InputBox';
import XcnButton from '../XcnButton/XcnButton';
import XcnModal from './XcnModal';
import DateTime from "react-datetime";
import { Form } from 'react-bootstrap';
import ExamService from '../../services/exam.service';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IAddShiftModal {
    showAddShiftModal: any
    setShowAddShiftModal: any
}

export default function AddShiftModal({
    showAddShiftModal,
    setShowAddShiftModal
}: IAddShiftModal) {

    const [shiftData, setShiftData] = useState<any>({});
    
    const params: any = useParams();


    const handleInputChange = (e: any) => {
        setShiftData({ ...shiftData, [e.target.name]: e.target.value });
    }

    const handleDateChange = (name: string, e: any) => {
        setShiftData({...shiftData, [name]: e})
    }

    const addShift = async() => {
        await ExamService.addShift(params.examId, shiftData.shiftName, shiftData.startTime, shiftData.endTime).then(res => {
            if(res.status === 200){
                toast.success("Added New Shift")
                setShowAddShiftModal(false);
            }
        }).catch((e:any)=>{
            toast.error(e.response.data.message || e.response.data)
        })
    }


    return (
        <>
            <XcnModal
                show={showAddShiftModal}
                handleClose={() => setShowAddShiftModal(false)}
                body={
                    <>
                        <InputBox
                            label="Shift Name"
                            type="text"
                            name="shiftName"
                            onChange={handleInputChange}
                        />
                        <div className="mt-3">

                            <Form.Label className="fw-bold xcn-text-12 text-secondary">
                                Start Time - End Time
                            </Form.Label>

                            <div className="d-flex justify-content-between align-items-center">
                                <DateTime onChange={ (e:any) => handleDateChange('startTime', e)}/>  
                                <div className="fw-bold mx-3"> - </div>
                                <DateTime onChange={ (e:any) => handleDateChange('endTime', e)} />
                            </div>
                        </div>
                        <XcnButton
                            variant="secondary"
                            text={
                                <>
                                    Create New Shift
                                </>
                            }
                            size="sm"
                            className="mt-3"
                            onClick={addShift}
                        />
                    </>
                }
                heading="Add Shift"
            />
        </>
    )
}
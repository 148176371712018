import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '../../components/Navbars/TopBar';
import XcnComponent from '../../components/XcnComponent/XcnComponent';
import adminRoutes, { adminBaseUrl } from '../../router/routes/admin.routes';

export default function AdminIndex() {
    return (
        <>
            <>
                <TopBar
                    routes={adminRoutes}
                    baseUrl={adminBaseUrl}
                    navUrlPos={3}
                />
                <XcnComponent
                    routes={adminRoutes}
                    baseUrl={adminBaseUrl}
                    navUrlPos={3}
                />
                <Outlet />
            </>
        </>
    )
}
import { faBookJournalWhills, faBuilding, faChalkboard, faChartLine, faHome, faNewspaper, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../../interfaces/IRouter';
import AdminStats from '../../../views/Admin/AdminStats';
import CenterBookingsIndex from '../../../views/CenterDash/CenterBookings/CenterBookings.index';
import CenterDashStats from '../../../views/CenterDash/CenterDashStats';
import CenterDetailsIndex from '../../../views/CenterDash/CenterDetails/CenterDetails.index';
import CenterLabIndex from '../../../views/CenterDash/CenterLabs/CenterLab.index';
import EmployeeIndex from "../../../views/CenterDash/Employee/Employee.index";


const centerDashRoutes: IRouter[] = [
    // {
    //     path: "statistics",
    //     element: <CenterDashStats />,
    //     navbarShow: true,
    //     activeIcon: faChartLine,
    //     name: "Statistics"
    // },
    {
        path: "center-details",
        element: <CenterDetailsIndex />,
        navbarShow: true,
        activeIcon: faNewspaper,
        name: "Center Details"
    },
    {
        path: "center-lab",
        element: <CenterLabIndex />,
        navbarShow: true,
        activeIcon: faNewspaper,
        name: "Center Labs"
    },
    {
        path: "center-Bookings",
        element: <CenterBookingsIndex />,
        navbarShow: true,
        activeIcon: faBookJournalWhills,
        name: "Bookings"
    },
    {
        path: "employee",
        element: <EmployeeIndex />,
        navbarShow: true,
        activeIcon: faBookJournalWhills,
        name: "Employees"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }

]

export default centerDashRoutes;
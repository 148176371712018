import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '../../components/Navbars/TopBar';
import XcnComponent from '../../components/XcnComponent/XcnComponent';
import corpRoutes, { corpBaseUrl } from '../../router/routes/corp.routes';

export default function CorpIndex(){
    return (
        <>
            <TopBar
                routes = {corpRoutes}
                baseUrl={corpBaseUrl}
                navUrlPos={3}
            />
            <XcnComponent 
                routes={corpRoutes}
                baseUrl={corpBaseUrl}
                navUrlPos={3}
            />
            <Outlet/>
        </>
    )
}
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Dropzone from 'react-dropzone';

import "./dropzone.css";

interface IUserDataDropZone{
    handleUploadedFile: any
}

export default function UserDataDropZone({ handleUploadedFile }: IUserDataDropZone) {

    return (
        <>
            <Dropzone onDrop={handleUploadedFile}>
                {({ getRootProps, getInputProps }: any) => (
                    <section>
                        <div className="xcn-dropzone">
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <FontAwesomeIcon icon={faFileUpload} className="xcn-text-xl text-secondary" />
                                <p className="mt-5">Drag 'n' drop some User Data here, or click to Select</p>
                            </div>
                        </div>

                    </section>
                )}
            </Dropzone>
        </>
    )
}
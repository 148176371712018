import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import XcnModal from './XcnModal'

interface IPasswordModal {
    show: any,
    setShow: any
}


const ResetPasswordModal = ({ show, setShow }: IPasswordModal) => {
    return (
        <>
            <XcnModal
                show={show ? true : false}
                handleClose={() => setShow(false)}
                body={
                    <>
                        <div className="flex-row justify-content-center align-items-center" style={{ textAlign: "center" }}>
                            <FontAwesomeIcon icon={faCircleExclamation} className="text-success" style={{ fontSize: "30px", opacity: 0.8 }} />
                            <div className="mt-3">
                                <span className="fw-bold">Password : </span><span>{show}</span>
                            </div>
                        </div>
                    </>
                }
                heading="Reset Password"
            />
        </>
    )
}

export default ResetPasswordModal
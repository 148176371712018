import { StreamPriorityOptions } from "http2";
import React, { useEffect, useState } from "react";
import { Row, Col, Modal, FloatingLabel, Button, Form } from "react-bootstrap";
import MyUploader from "../Dropzone/dropzone";
import CenterService from "../../services/center.service";
import { toast } from "react-toastify";
import Select from "react-select";
import { WorkerCategoryEnum } from "../../enum/WorkerCategory.enum";
import { useParams } from "react-router-dom";
import { AdminService } from "../../services/admin.service";
import { UploadService } from "../../services/upload.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import moment from "moment";
import { employeeValidation } from "../../validations/employee.validation";
import PreviewModal from "./Preview.modal";
import { ObjectHelper } from "../../helpers/object.helper";

interface IEditEmployeeModal {
  show: boolean;
  handleClose: any;
  reload: any
}

export default function EditEmployeeModal(props: IEditEmployeeModal) {
  const params: any = useParams();

  const [employee, setEmployee] = useState<any>({});
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [employeeType, setEmployeeType] = useState<any>();;
  const [selectedEmployeeType, setSelectedEmployeeType] = useState<any>();
  const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined);

  const onValueChange = (e: any) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const getEmployeeById = async () => {
    await CenterService.getEmployeeById(props.show)
      .then((res) => {
        if (res?.status === 200) {
          setEmployee(res?.data);
          setSelectedCategory({ label: res?.data?.workerCategory, value: res?.data?.workerCategory });
          setSelectedEmployeeType({ label: res?.data?.typeOfEmployee, value: res?.data?.typeOfEmployee });
        }
      })
      .catch((err) => {
        toast.error("Unable to get employee details");
      });
  };

  useEffect(() => {
    if (props.show !== undefined) {
      getEmployeeById();
    }
  }, [props.show]);


  const getEmployeeType = async () => {
    await CenterService.getEmployeeType().then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setEmployeeType(res.data.enums.map((data: any) => {
          return {
            label: data,
            value: data
          }
        }));
      }
    })
  }

  useEffect(() => {
    getEmployeeType();
  }, [])

  const editEmployee = async () => {
    const data = {
      idCard: employee.idCard ? employee.idCard : undefined,
      employeeName: employee.employeeName,
      workerCategory: selectedCategory.value,
      typeOfEmployee: selectedEmployeeType.value,
      gender: employee.gender,
      email: employee.email,
      address: employee.address,
      dob: employee.dob,
      image: employee.image ? employee.image : undefined,
      phoneNumber: employee.phoneNumber,
      aadhar: employee.aadhar,
      fatherName: employee.fatherName,
      motherName: employee.motherName,
      aadhar_front: employee.aadhar_front ? employee.aadhar_front : undefined,
      aadhar_back: employee.aadhar_back ? employee.aadhar_back : undefined,
      pan: employee.pan ? employee.pan : undefined,
    };

    const validateData = employeeValidation.validate(ObjectHelper.removeEmpty(data));
    if (validateData.error) {
      toast.error("Error: " + validateData.error);
      return false;
    }

    await CenterService.editEmployee(employee._id, data)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Employee Detail Edited Successfully");
          props.handleClose(undefined);
          props.reload();
        }
      })
      .catch((error) => {
        console.error(error.response.data);
        toast.error("Something went wrong " + error.response.status);
      });
  };

  const fileSelectedHandler = async (e: any, name: string) => {
    const fd = new FormData();
    // let arr=[];
    for (let i of e) {
      fd.append("upload", i);
    }
    await UploadService.uploadFileToS3(fd).then((res: any) => {
      if (res.status === 200) {
        setEmployee({ ...employee, [name]: res.data[0] });
        toast.success(`File(s) uploaded`);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return (
    <>
      <PreviewModal
        show={showPreviewModal}
        setShow={setShowPreviewModal}
      />

      <Modal
        show={props.show}
        onHide={() => props.handleClose(undefined)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="fw-bold text-primary">Personal Info</h6>

          <Row>
            <Col md="12">
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="employeeName"
                  onChange={(e) => onValueChange(e)}
                  value={employee && employee.employeeName}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Phone Number"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  onChange={(e) => onValueChange(e)}
                  value={employee && employee.phoneNumber}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  type="email"
                  name="email"
                  onChange={(e) => onValueChange(e)}
                  value={employee && employee.email}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Address"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="address"
                  onChange={(e) => onValueChange(e)}
                  value={employee && employee.address}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Aadhar"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="aadhar"
                  onChange={(e) => onValueChange(e)}
                  value={employee && employee.aadhar}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Father Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="fatherName"
                  onChange={(e) => onValueChange(e)}
                  value={employee && employee.fatherName}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Mother Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="motherName"
                  onChange={(e) => onValueChange(e)}
                  value={employee && employee.motherName}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingSelect"
                label="Gender"
                className="mb-3"
              >
                <Form.Select
                  name="gender"
                  onChange={(e) => onValueChange(e)}
                  value={employee && employee.gender}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Form.Select>
              </FloatingLabel>

              <hr className="mx-3 mt-5" />
              <h6 className="fw-bold text-primary">Other Info</h6>
              <Form.Label className="text-muted xcn-text-10">DOB</Form.Label>
              <DatePicker
                selected={moment(employee && employee?.dob).toDate() || null}
                name="dob"
                className="form-control"
                onChange={(e: Date) => setEmployee({ ...employee, dob: e })}
              />
              <Form.Label className="text-muted xcn-text-10">
                Worker Category
              </Form.Label>
              <Select
                options={Object.values(WorkerCategoryEnum).map(
                  (category: any) => {
                    return {
                      value: category,
                      label: category.split("_").join(" ").toUpperCase(),
                    };
                  }
                )}
                placeholder="Category"
                value={selectedCategory}
                className="mb-3"
                onChange={(e: any) => {
                  setSelectedCategory(e);
                }}
              />
              <Form.Label className="text-muted xcn-text-10">
                Type of Employee
              </Form.Label>
              <Select
                value={selectedEmployeeType}
                options={employeeType}
                placeholder="Category"
                className="mb-3"
                onChange={(e: any) => setSelectedEmployeeType(e)}
              />
              <div className="d-flex justify-content-between align-items-center mx-5">
                <div className="mt-4 text-secondary flex fw-bold w-100">
                  <h6>
                    User Image{" "}
                    {employee.image && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        <FontAwesomeIcon
                          icon={faEye}
                          className="text-primary ms-2"
                          onClick={() => setShowPreviewModal(employee.image)}
                        />
                      </>
                    )}{" "}
                  </h6>
                  <div className="d-flex gap-2">
                    {employee && employee?.image && (
                      <div>
                        <img
                          style={{ width: "7rem", height: "10rem" }}
                          src={employee && employee?.image}
                          alt="Uploaded Image"
                        />
                      </div>
                    )}
                    <MyUploader
                      fileSelectedHandler={(e: any) =>
                        fileSelectedHandler(e, "image")
                      }
                    />
                  </div>
                </div>

                <div className="mt-4 text-secondary flex fw-bold mt-4 w-100 mx-5">
                  <h6>
                    Id Card{" "}
                    {employee.idCard && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        <FontAwesomeIcon
                          icon={faEye}
                          className="text-primary ms-2"
                          onClick={() => setShowPreviewModal(employee.idCard)}
                        />
                      </>
                    )}
                  </h6>
                  <div className="d-flex gap-2">
                    {employee && employee?.idCard && (
                      <div>
                        <img
                          style={{ width: "7rem", height: "10rem" }}
                          src={employee && employee.idCard}
                          alt="Uploaded Image"
                        />
                      </div>
                    )}

                    <MyUploader
                      fileSelectedHandler={(e: any) =>
                        fileSelectedHandler(e, "idCard")
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mx-5">
                <div className="mt-4 text-secondary flex fw-bold w-100">
                  <h6>
                    Aadhar Front{" "}
                    {employee.aadhar_front && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        <FontAwesomeIcon
                          icon={faEye}
                          className="text-primary ms-2"
                          onClick={() => setShowPreviewModal(employee.aadhar_front)}
                        />
                      </>
                    )}{" "}
                  </h6>
                  <div className="d-flex gap-2">
                    {employee && employee.aadhar_front && (
                      <div>
                        <img
                          style={{ width: "7rem", height: "10rem" }}
                          src={employee && employee.aadhar_front}
                          alt="Uploaded Image"
                        />
                      </div>
                    )}

                    <MyUploader
                      fileSelectedHandler={(e: any) =>
                        fileSelectedHandler(e, "aadhar_front")
                      }
                    />
                  </div>
                </div>

                <div className="mt-4 text-secondary flex fw-bold mt-4 w-100 mx-5">
                  <h6>
                    Aadhar Back{" "}
                    {employee.aadhar_back && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        <FontAwesomeIcon
                          icon={faEye}
                          className="text-primary ms-2"
                          onClick={() => setShowPreviewModal(employee.aadhar_back)}
                        />
                      </>
                    )}
                  </h6>
                  <div className="d-flex gap-2">
                    {employee && employee.aadhar_back && (
                      <div>
                        <img
                          style={{ width: "7rem", height: "10rem" }}
                          src={employee && employee.aadhar_back}
                          alt="Uploaded Image"
                        />
                      </div>
                    )}

                    <MyUploader
                      fileSelectedHandler={(e: any) =>
                        fileSelectedHandler(e, "aadhar_back")
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mx-5">
                <div className="mt-4 text-secondary flex fw-bold w-100">
                  <h6>
                    Pan Card{" "}
                    {employee.pan && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        <FontAwesomeIcon
                          icon={faEye}
                          className="text-primary ms-2"
                          onClick={() => setShowPreviewModal(employee.pan)}
                        />
                      </>
                    )}{" "}
                  </h6>
                  <div className="d-flex gap-2">
                    {employee && employee.pan && (
                      <div>
                        <img
                          style={{ width: "7rem", height: "10rem" }}
                          src={employee && employee.pan}
                          alt="Uploaded Image"
                        />
                      </div>
                    )}

                    <MyUploader
                      fileSelectedHandler={(e: any) =>
                        fileSelectedHandler(e, "pan")
                      }
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Button
            className="mt-5 fw-bold"
            size="sm"
            onClick={editEmployee}
          >
            Edit Employee
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

import { faChevronLeft, faChevronRight, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { isConstructorDeclaration } from 'typescript';
import XcnButton from '../../../../../components/XcnButton/XcnButton';
import ExamService from '../../../../../services/exam.service';
import MappingService from '../../../../../services/mapping.service';


interface IFileMapping {
    setSelectedStep: any
    selectedStep: number
}

interface ISheetData {
    exmcen: any[]
    fields: any[]
}

export default function FileMapping(props: IFileMapping) {

    const params: any = useParams();
    const navigate = useNavigate();

    const [sheetData, setSheetData] = useState<ISheetData>();

    const [mappingData, setMappingData] = useState<any[]>([]);

    const getSheetFields = async () => {
        await ExamService.getSheetFields(params.jobId).then(res => {
            if (res.status === 200) {
                setSheetData(res.data.data)
            }
        })
    }

    const getFieldMappingByJobId = async () => {
        await MappingService.getFieldMappingForJobId(params.jobId).then(res=>{
            if(res.status === 200){
                setMappingData(res.data.mappings)
            }
        })
    }

    useEffect(() => {
        getSheetFields()
        getFieldMappingByJobId()
    }, [])
               
    const handleInputChange = (e: any, systemValue: any) => {
        let currentMappingData = mappingData;

        let newMappingData = currentMappingData.filter(data => data.exmcen != systemValue.value)

        newMappingData.push({
            "sheet": e.value.sheet,
            "sheetIndex": e.value.sheetIndex,
            "exmcen": systemValue.value
        })

        setMappingData(newMappingData);
    }

    const onDataSave = async () => {
        if (mappingData) {
            await ExamService.createFieldMapping(params.jobId, mappingData).then(res=>{
                if(res.status === 200){
                    toast.success("File Mapping Added");
                }
            }).catch(e=>{
                toast.error("Something went wrong")
            })
        }
    }

    return (
        <>
            <Container>
                <Card className="xcn-card mt-3">
                    <h5 className="text-secondary fw-bold">
                        File Mapping
                    </h5>
                    <Table borderless striped className="mt-3">
                        <thead>
                            <tr className="text-secondary">
                                <th>#</th>
                                <th>System Value</th>
                                <th>Map to Sheet Column Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sheetData && sheetData.exmcen.length > 0 ? sheetData.exmcen.map((data: any, index: number) => {
                                    return (
                                        <tr className="xcn-table-tr" key={data.value}>
                                            <td className="fw-bold">
                                                {index + 1}
                                            </td>
                                            <td className="fw-bold xcn-text-14">
                                                {data.label}
                                            </td>
                                            <td>
                                                <Select
                                                    options={
                                                        sheetData.fields
                                                    }
                                                    value={mappingData.map((mapping: any)=>{
                                                        if(mapping.exmcen === data.value){
                                                            return {
                                                                value: {sheet: mapping.sheet, sheetIndex: mapping.sheetIndex},
                                                                label: mapping.sheet
                                                            }
                                                        }
                                                    })[index]}
                                                    onChange={(e) => handleInputChange(e, data)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={3}>
                                        Unable to Fetch Column Names from Sheet
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-between align-items-center">
                        <XcnButton
                            text={<>
                                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                                Back
                            </>}
                            variant="danger"
                            onClick={() => navigate("/examdash/" + params.examId + "/exam-mapping/select-data")}
                        />
                        <XcnButton
                            text={<>
                                Save Data
                                <FontAwesomeIcon icon={faSave} className="ms-2" />
                            </>}
                            variant="secondary"
                            onClick={onDataSave}
                        />
                        <XcnButton
                            text={<>
                                Next
                                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                            </>}
                            variant="success"
                            onClick={() => {
                                onDataSave()
                                props.setSelectedStep(props.selectedStep + 1)
                            }}
                        />
                    </div>
                </Card>

            </Container>
        </>
    )
}
import React, { useEffect, useState } from "react";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import InputBox from "../../../../components/Input/InputBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import CenterService from "../../../../services/center.service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CommonService from "../../../../services/common.service";
import Location from "./Location";

interface ICenterContact {
  onChange: any;
  updateCenterDetails: any;
  updateCenterFacilities: any;
  onUpdatingFacilities: any;
  handlePincodeDetails: any;
  pincode: any;
  reload: any;
  coordinatesData: any;
}

export default function CenterContact(props: ICenterContact) {
  const params: any = useParams();
  const [editCenterDetails, setEditCenterDetails] = useState<boolean>(false);
  const [editCenterFacilities, setEditCenterFacilities] =
    useState<boolean>(false);

  const [pincode, setPincode] = useState<any>();
  const [pincodeDetails, setPincodeDetails] = useState<any>();

  const editButtonKey = () => {
    setEditCenterDetails(!editCenterDetails);
  };

  const editOtherDetailButtonKey = () => {
    setEditCenterFacilities(!editCenterFacilities);
  };

  const updateCenterDetails = async () => {
    if (!pincodeDetails) {
      toast.error("Pincode is Mandatory: Re-enter Pincode");
      return false;
    }

    await CenterService.updateCenterBasicDetails(
      params.centerId,
      props.updateCenterDetails,
      {
        district: pincodeDetails?.district,
        // stateId: pincodeDetails.stateId,
        districtId: pincodeDetails?._id,
        pincode: pincodeDetails?.pincode,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          setEditCenterDetails(false);
          props.reload();
        }
      })
      .catch((error) => {
        console.error(error.response);
        toast.error(
          error.response.status +
          "Something went wrong " +
          error.response.data.message
        );
      });
  };

  const updateCenterFacilities = async () => {
    await CenterService.updateCenterFacilities(
      params.centerId,
      props.updateCenterFacilities
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          setEditCenterFacilities(false);
          props.reload();
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };

  const getPincodeDetails = async () => {
    if (!pincode) {
      toast.error("Pincode is Mandatory");
      return false;
    }
    await CommonService.getPincodeInfo(pincode).then((res) => {
      if (res.status === 200) {
        setPincodeDetails(res.data);
        const areaData: any = res.data.area.map((data: any) => {
          return {
            label: data.officename,
            value: data._id,
          };
        });
        // setAreaSelectOptions(areaData);
      }
    });
  };

  return (
    <>
      <Card className="xcn-card mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="text-secondary fw-bold">Basic Details</h5>
          <div>
            {editCenterDetails ? (
              <XcnButton
                text={
                  <>
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Save
                  </>
                }
                variant="success"
                onClick={updateCenterDetails}
              />
            ) : (
              <XcnButton
                text={
                  <>
                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                    Edit
                  </>
                }
                variant="warning"
                onClick={editButtonKey}
              />
            )}
          </div>
        </div>
        <Row>
          <Col>
            <InputBox
              type="text"
              name="spocName"
              label="SPOC Name"
              defaultValue={props.updateCenterDetails?.spocName}
              disabled={!editCenterDetails}
              onChange={props.onChange}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <InputBox
              type="email"
              name="centerEmail"
              label="Email Address"
              defaultValue={props.updateCenterDetails?.centerEmail}
              disabled={!editCenterDetails}
              onChange={props.onChange}
            />
          </Col>
          <Col md={6}>
            <InputBox
              type="text"
              name="centerContact"
              label="Contact"
              defaultValue={props.updateCenterDetails?.centerContact}
              disabled={!editCenterDetails}
              onChange={props.onChange}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <InputBox
              type="text"
              name="name"
              label="Registered Name"
              defaultValue={props.updateCenterDetails?.name}
              disabled={!editCenterDetails}
              onChange={props.onChange}
            />
          </Col>
          <Col md={6}>
            <InputBox
              type="text"
              name="gstNumber"
              label="GST Number"
              defaultValue={props.updateCenterDetails?.gstNumber}
              disabled={!editCenterDetails}
              onChange={props.onChange}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <InputBox
              type="text"
              name="address"
              label="Address"
              defaultValue={props.updateCenterDetails?.address}
              disabled={!editCenterDetails}
              onChange={props.onChange}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={9}>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Pincode"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="110057"
                    defaultValue={props.pincode && props.pincode.pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    disabled={!editCenterDetails}
                  />
                </FloatingLabel>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <Button
              variant="primary"
              onClick={getPincodeDetails}
              className="ms-2"
              disabled={!editCenterDetails}
            >
              Get Pincode Data
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <InputBox
              type="text"
              name="state"
              label="State"
              defaultValue={
                pincodeDetails ? pincodeDetails.state : props.pincode?.state
              }
              disabled={true}
              onChange={props.handlePincodeDetails}
            />
          </Col>
          <Col md={6}>
            <InputBox
              type="text"
              name="district"
              label="City"
              defaultValue={
                pincodeDetails
                  ? pincodeDetails.district
                  : props.pincode?.district
              }
              disabled={true}
              onChange={props.handlePincodeDetails}
            />
          </Col>
        </Row>
      </Card>
      <Location
        onChange={props.onChange}
        updateCenterDetails={props.updateCenterDetails}
        handlePincodeDetails={props.handlePincodeDetails}
        pincode={props.pincode}
      />
      <Card className="xcn-card mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="text-secondary fw-bold">Other Details</h5>
          <div>
            {editCenterFacilities ? (
              <XcnButton
                text={
                  <>
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Save
                  </>
                }
                variant="success"
                onClick={updateCenterFacilities}
              />
            ) : (
              <XcnButton
                text={
                  <>
                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                    Edit
                  </>
                }
                variant="warning"
                onClick={editOtherDetailButtonKey}
              />
            )}
          </div>
        </div>

        {/* <Row className="mt-3">
          <Col md={6}>
            <InputBox
              type="number"
              name="venueSchedulingCount"
              label="Scheduling Count"
              defaultValue={props.updateCenterFacilities?.venueSchedulingCount}
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
          <Col md={6}>
            <InputBox
              type="number"
              name="venueSchedulingCountWithSD"
              label="Scheduling Count With SD Norms (50%)"
              defaultValue={
                props.updateCenterFacilities?.venueSchedulingCountWithSD
              }
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <InputBox
              type="number"
              name="venuePersonCapacity"
              label="Capacity"
              defaultValue={props.updateCenterFacilities?.venuePersonCapacity}
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <InputBox
              type="text"
              name="googleCoordinate"
              label="Google Coordinate"
              defaultValue={
                props.coordinatesData &&
                props.coordinatesData?.lat + "," + props.coordinatesData?.long
              }
              disabled
            // onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row> */}
        <Row className="mt-3">
          <Col md={6}>
            <InputBox
              type="number"
              name="venueSchedulingCount"
              label="Scheduling Count"
              defaultValue={props.updateCenterFacilities?.venueSchedulingCount}
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
          <Col md={6}>
            <InputBox
              type="number"
              name="venueSchedulingCountWithSD"
              label="Scheduling Count With SD Norms (50%)"
              defaultValue={
                props.updateCenterFacilities?.venueSchedulingCountWithSD
              }
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <InputBox
              type="number"
              name="venuePersonCapacity"
              label="Capacity"
              defaultValue={props.updateCenterFacilities?.venuePersonCapacity}
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <InputBox
              type="text"
              name="googleMapLink"
              label="Google Map Link"
              defaultValue={props.updateCenterFacilities?.googleMapLink}
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AddCenterModal from '../../../components/Modals/AddCenter.modal';
import CorpService from '../../../services/corp.service';
import ViewCorpCenters from './ViewCorpCenters';


export default function ViewCorpDetails() {
    const params: any = useParams();

    const [corpData, setcorpdata] = useState<any>({
        orgName: "",
        orgEmail: "",
        _id: ""
    });


    const getCorpDetails = async () => {
        await CorpService.getCorpDetails(params.corpId).then(res => {
            if (res.status === 200) {
                setcorpdata(res.data)
            }
        }).catch(e => [
            console.log(e)
        ])
    }

    useEffect(() => {
        getCorpDetails()
    }, [])
    return (
        <>

            <Container className="xcn-conatiner">
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div>
                                <span className="fw-bold">
                                    Corporate/Org Name:
                                </span>
                                <span className="ms-2">
                                    {corpData.orgName}
                                </span>
                            </div>
                            <div>
                                <span className="fw-bold">
                                    Corporate/Org Email:
                                </span>
                                <span className="ms-2">
                                    {corpData.orgEmail}
                                </span>
                            </div>

                        </div>
                    </div>
                </Card>
                <ViewCorpCenters />
            </Container>

        </>
    )
}
import { IAddCenter } from "../interfaces/ICorpService.interface";
import makeRequest, { makeParams } from "./makeRequest";
import urls from "./urls";

export default class CorpService {
  static async getAllCorps() {
    return await makeRequest(urls.corporate.getAllCorps, "GET");
  }
  static async allCorps(corpId: string, search: string, page: any, count: any) {
    const params: any = makeParams([
      {
        index: "search",
        value: search
      },
      {
        index: "corpId",
        value: corpId
      },
      {
        index: "page",
        value: page
      },
      {
        index: "count",
        value: count
      }
    ])
    return await makeRequest(
      urls.corporate.searchCorps + params,
      "GET"
    );
  }
  static async getcorpcenters(corpId: string) {
    return await makeRequest(
      urls.corporate.getcorpcenters + "/" + corpId,
      "GET"
    );
  }
  static async getCorpDetails(id?: string) {

    return await makeRequest(urls.corporate.getCorpDetails + "/" + id, "GET");
  }
  static async removeCorpUser(id?: string) {
    return await makeRequest(urls.corporate.removeCorps + "/" + id, "DELETE");
  }

  static async addCenterToCorp(centerData: IAddCenter) {
    return await makeRequest(urls.corporate.addCenter, "POST", centerData);
  }
  static async searchCorp(param?: any) {
    const params = makeParams([
      {
        index: "search",
        value: param,
      },
    ]);

    return await makeRequest(urls.centers.searchCenter + params, "GET");
  }
  static async searchCenter(param?: any) {
    const params = makeParams([
      {
        index: "search",
        value: param,
      },
    ]);

    return await makeRequest(urls.centers.searchCenter + params, "GET");
  }

  static async resetPassword(corpId?: string) {
    return await makeRequest(urls.corporate.resetPassword + "/" + corpId, "PUT");
  }
}


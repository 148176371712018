import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import CreateApiKey from './CreateApiKey';
import ViewAllKeys from './ViewAllKeys';

export default function ViewKeys() {

    const [reload, setReload] = useState<boolean>(false);
    return (
        <>
            <Container className="xcn-container">
                <div className="mt-4">
                    <CreateApiKey reload={() => setReload(!reload)} />
                </div>
                <div>
                    <ViewAllKeys reload={reload} />
                </div>
            </Container>
        </>
    )
}
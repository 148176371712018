import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CenterApprovals from "../../../../components/Centers/CenterApprovals";
import CenterBuildingsTable from "../../../../components/Centers/CenterBuildings.table";
import AddBuildingModal from "../../../../components/Modals/AddBuilding.modal";
import XcnModal from "../../../../components/Modals/XcnModal";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import CenterService from "../../../../services/center.service";

export default function CenterBuilding() {
  const params: any = useParams();

  const [refreshBuildings, setReFreshBuildings] = useState<string>("")
  const [buildings, setBuildings] = useState<any>([]);
  const [showAddBuildingPlan, setShowAddBuildingPlan] =
    useState<boolean>(false);

  const getCenterBuildingPlan = async () => {
    await CenterService.getCenterBuildingPlan(params.centerId).then((res) => {
      setBuildings(res.data);
    });
  };

  useEffect(() => {
    getCenterBuildingPlan();
  }, [showAddBuildingPlan, refreshBuildings]);

  return (
    <>
      <AddBuildingModal
        showAddBuildingPlan={showAddBuildingPlan}
        setShowAddBuildingPlan={setShowAddBuildingPlan}
        reload={getCenterBuildingPlan}
      />

      <Container className="xcn-container">
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-secondary fw-bold">Center Buildings</h5>
            <div>
              <XcnButton
                text={
                  <>
                    Add New Building <FontAwesomeIcon icon={faPlusCircle} />
                  </>
                }
                onClick={() => setShowAddBuildingPlan(true)}
                size="sm"
              />
            </div>

          </div>
          <div>
            <CenterBuildingsTable
              buildings={buildings}
              refresh={setReFreshBuildings}
              onSendData={getCenterBuildingPlan}
            />
          </div>
        </Card>
      </Container>
    </>
  );
}

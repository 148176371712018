import React from 'react';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

interface ICenterBuildingsTable {
    centers?: any[]
    removeCenter: any
}

export default function MappingCentersTable(props: ICenterBuildingsTable) {
    

    return (
        <>

            <Table borderless striped size='sm'>
                <thead>
                    <tr className="text-center">
                        <th>#</th>
                        <th>Center Name</th>
                        <th>Center Id</th>
                        <th>Capacity</th>
                        <th>Contact</th>
                        <th>Address</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.centers && props.centers.length > 0 ?
                            props.centers.map((data: any, index: number) => {
                                return (
                                    <tr className="xcn-table-tr text-center" key={data._id}>
                                        <td className="fw-bold xcn-text-12">
                                            {index + 1}
                                        </td>
                                        <td className="xcn-text-12">
                                            {data.name}
                                        </td>
                                        <td className="fw-bold xcn-text-12">
                                            {data.centerId}
                                        </td>
                                        <td className="font-monospace text-secondary">
                                            {data.seatCapacity}
                                        </td>
                                        <td className="text-info">
                                            {data.centerContact}
                                        </td><td className="fw-bold font-monospace xcn-text-10 secondary">
                                            {data.address}
                                        </td>
                                        <td>
                                            <FontAwesomeIcon className='text-danger xcn-link-pointer' icon={faTimesCircle} onClick={()=>props.removeCenter(data)}/>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={5} className="text-danger fw-bold">
                                    No Centers Selected
                                </td>
                            </tr>
                    }

                </tbody>
            </Table>
        </>
    )
}
import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useParams } from 'react-router-dom';
import TopBar from '../../../components/Navbars/TopBar';
import XcnComponent from '../../../components/XcnComponent/XcnComponent';
import examDetailsRoutes, { examDetailsBaseUrl } from '../../../router/routes/examDash/examDetails.rotues';

export default function ExamDetailsIndex(){
    const params: any = useParams();
    
    return (
        <>
          <TopBar
            routes={examDetailsRoutes}
            baseUrl={examDetailsBaseUrl(params.examId)}
            navUrlPos={3}
          />

          <XcnComponent 
            routes={examDetailsRoutes}
            baseUrl={examDetailsBaseUrl(params.examId)}
            navUrlPos={3}
          />
            <Outlet/>
        </>
    )
}
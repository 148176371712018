import Joi from "joi";

const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/

export const employeeValidation = Joi.object({
  employeeName: Joi.string().required(),
  phoneNumber: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  address: Joi.string().required(),
  aadhar: Joi.string().required(),
  fatherName: Joi.string().required(),
  motherName: Joi.string().optional(),
  name: Joi.string().optional(),
  gender: Joi.string().optional(),
  workerCategory: Joi.string().optional(),
  typeOfEmployee: Joi.string().optional(),
  dob: Joi.string().optional(),
  image: Joi.string().optional(),
  idCard: Joi.string().optional(),
  aadhar_front: Joi.string().optional(),
  aadhar_back: Joi.string().optional(),
  pan: Joi.string().optional(),
}).required();


import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../../interfaces/IRouter';
import ExamShifts from '../../../views/ExamDash/ExamDetails/ExamShifts/ExamShifts';
import ViewExamInfo from '../../../views/ExamDash/ExamDetails/ViewExamInfo/ViewExamInfo';

export function examDetailsBaseUrl(examId: string) {
    return "/exam-details/"+examId+"/"
}

const examDetailsRoutes: IRouter[] = [
    {
        path: "shifts",
        element: <ExamShifts/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "Shifts"
    },
    {
        path: "info",
        element: <ViewExamInfo/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "Info"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }
    
    ]

export default examDetailsRoutes;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SelectMapping from '../../../../components/Mapping/SelectMapping';
import { JobStatusEnum } from '../../../../enum/jobStatus.enum';
import FileMapping from './MappingSteps/FileMapping';
import JobStepCounter, { IJobStepInterface } from './JobStepCounter';
import SelectCities from './MappingSteps/SelectCities';
import ShiftsMapping from './MappingSteps/ShiftsMapping';
import SelectCenters from './MappingSteps/SelectCenters/SelectCenters';
import FinalizeAllotment from './MappingSteps/FinalizeAllotment';

export default function JobMappingExamMapping() {
    const params: any = useParams();

    const [selectedStep, setSelectedStep] = useState<number>(1)

    const [steps, setSteps] = useState<IJobStepInterface[]>([
        {
            stepName: "Select File",
        },
        {
            stepName: "File Mapping",
            component: FileMapping
        },
        {
            stepName: "Select Cities",
            component: SelectCities
        },
        {
            stepName: "Shifts Data",
            component: ShiftsMapping
        },
        {
            stepName: "Select Centers",
            component: SelectCenters
        },
        {
            stepName: "Finalize Allotment",
            component: FinalizeAllotment
        }
        // {
        //     stepName: "",
        //     component: FinalizeAllotment
        // }
    ])

    // const handleNavSelection = () => {
    //     let currentSteps = steps;

    //     for(let step in currentSteps){
    //         if(Number(step) < selectedStep){
    //             currentSteps[Number(step)].status = JobStatusEnum.COMPLETED
    //         }
    //         if(Number(step) == selectedStep) {
    //             currentSteps[Number(step)].status = JobStatusEnum.INPROGRESS
    //         }
    //         if(Number(step) > selectedStep){
    //             currentSteps[Number(step)].status = JobStatusEnum.NOTVISITED
    //         }
    //     }
    //     setSteps(currentSteps);
    // }


    // useEffect(() => {
    //     handleNavSelection()
    // }, [selectedStep])

    return (
        <>
            <JobStepCounter
                steps={steps}
                key={selectedStep}
                selectedStep={selectedStep}
            />
            
            <SelectMapping 
                component={steps[selectedStep].component}
                setSelectedStep={setSelectedStep}
                selectedStep={selectedStep}
            />

        </>
    )
}
import React, { useEffect, useState } from "react";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import CenterService from "../../../../services/center.service";

interface MapContainerProps {
  centerId: string;
  currentPosition: any;
  setCurrentPosition: any;
}

const MapContainer = ({
  centerId,
  currentPosition,
  setCurrentPosition,
}: MapContainerProps) => {

  const success = (position: any) => {
    setCurrentPosition(position);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  }, [navigator.geolocation]);

  const createCenterCoordinates = async () => {
    await CenterService.createCenterCoordinates(centerId, {
      coordinates: {
        lat:
          String(
            currentPosition &&
              currentPosition.coords &&
              currentPosition.coords.latitude
          ) || "",
        long:
          String(
            currentPosition &&
              currentPosition.coords &&
              currentPosition.coords.longitude
          ) || "",
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    createCenterCoordinates();
  }, [currentPosition]);

  const mapStyles = {
    height: "80vh",
    width: "100%",
  };

  const mapOptions = {
    disableDefaultUI: true, // Remove default UI controls
    zoomControl: false, // Disable zoom control
    fullscreenControl: false, // Disable fullscreen control
  };

  const onMarkerDragEnd = (e: any) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const data = {
      coords: {
        latitude: lat,
        longitude: lng,
      },
    };
    setCurrentPosition(data);
  };

  console.log(currentPosition);

  return (
    <div
      className="
      d-flex
      justify-content-center
      align-items-center
      mt-5"
    >
      <LoadScript
        googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
      >
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={20}
          center={{
            lat:
              currentPosition &&
              currentPosition.coords &&
              currentPosition.coords.latitude,
            lng:
              currentPosition &&
              currentPosition.coords &&
              currentPosition.coords.longitude,
          }}
          options={mapOptions}
        >
          <Marker
            position={{
              lat:
                currentPosition &&
                currentPosition.coords &&
                currentPosition.coords.latitude,
              lng:
                currentPosition &&
                currentPosition.coords &&
                currentPosition.coords.longitude,
            }}
            onDragEnd={(e) => onMarkerDragEnd(e)}
            draggable={true}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapContainer;

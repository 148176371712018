import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '../../components/Navbars/TopBar';
import XcnComponent from '../../components/XcnComponent/XcnComponent';
import centerRoutes, { centerBaseUrl } from '../../router/routes/center.routes';

export default function CenterIndex(){
    return (
        <>
            <TopBar
                routes = {centerRoutes}
                baseUrl={centerBaseUrl}
                navUrlPos={3}
            />
            <XcnComponent 
                routes={centerRoutes}
                baseUrl={centerBaseUrl}
                navUrlPos={3}
            />
            <Outlet/>
        </>
    )
}
import { faLaptopCode, faRefresh, faSave, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import XcnButton from "../../../../../../components/XcnButton/XcnButton";
import Select from 'react-select';
import MappingService from "../../../../../../services/mapping.service";
import { useParams } from "react-router-dom";
import { ISelectedCenters } from "../../../../../../interfaces/ICenters.interface";
import MappingCentersTable from "../../../../../../components/Mapping/MappingCenters.Table";
import XcnModal from "../../../../../../components/Modals/XcnModal";


interface ISelectedCenterDist {
    mappingCity: string
    requiredSeats: number
    bookedSeats: number
    shifts: any
    setChoiceSavedCenters: any
    choice: string
}

export default function SelectCenterDist(props: ISelectedCenterDist) {

    const params: any = useParams();

    const [capacity, setCapacity] = useState<number>(0);//District capicity
    const [bookedSeats, setBookedSeats] = useState<number>(0);

    const [selectedCenters, setSelectedCenters] = useState<ISelectedCenters[]>([]);// center list dist name wise
    const [availableCenters, setAvailableCenters] = useState<any[]>([]);

    const [selectedShift, setSelectedShift] = useState<string>(props.shifts[0]._id)

    const [showTable, setShowTable] = useState<boolean>(false);
    const [choiceCenterData, setChoiceCenterData] = useState<string>();
    const [getCenterAllData, setGetCenterAllData] = useState<any>();

    const saveMappingToChoice = () => {
        props.setChoiceSavedCenters(selectedCenters, props.mappingCity)
    }

    const getCenterCapacity = async () => {
        await MappingService.getDistCapacityByJobId(params.jobId, props.mappingCity).then(res => {
            if (res.status === 200) {
                setCapacity(res.data[0].nodes)
            }
        })
    }


    const autoSelectCenters = async () => {
        const seats: number = props.requiredSeats - props.bookedSeats;
        await MappingService.autoSelectCentersForDist(params.examId, props.mappingCity, seats).then(res => {
            setSelectedCenters(res.data)
        }
        )
    }
    const getSeletedCenters = async () => {
        //const seats: number = props.requiredSeats - props.bookedSeats;
        await MappingService.getCenterData(params.jobId, getCenterAllData).then(res => {
            if(res.status === 200) {
                setChoiceCenterData(res.data)
                console.log("Data Send Successfully from getAllCenters");
            }
        }).catch((err) => {
            console.log("Something went wrong from getSelectedCenters", err);
            
        })
    }

    const createSelectOptionDataForCenters = (centerData: any) => {
        // console.log(centerData);
        // setGetCenterAllData(centerData);
        const rtnData: any[] = centerData.map((data: any, index: number) => {
            return {
                value: data.centerId,
                label: <>
                    <span className='fw-bold xcn-text-12 text-primary'>
                        {data.centerId}
                    </span> -  <span className='fw-bold text-secondary'>
                        {data.name}
                    </span>
                </>,
                otherInfo: data
            }
        })
        return rtnData;
    }

    const getAvailableCentersInDistByJobId = async () => {
        await MappingService.getCentersInDistByJobId(params.jobId, props.mappingCity).then(res => {
            if (res.status === 200) {
                const availableCentersData: any[] = createSelectOptionDataForCenters(res.data)
                setAvailableCenters(availableCentersData);
            }
        })
    }


    const handleSelectChange = (e: any) => {
        let newSelectedCenters = selectedCenters.filter(data => data.shiftId != selectedShift)
        newSelectedCenters.push({
            shiftId: selectedShift,
            centers: e.map((data: any) => {
                return data.otherInfo
            })
        })
        setSelectedCenters(newSelectedCenters);
    }

    const calculateValue = (centersData: any, shiftSelected: any) => {
        for (let shift of centersData) {
            if (shift.shiftId === shiftSelected) {
                return createSelectOptionDataForCenters(shift.centers)
            }
        }
    }

    const calculateSelectedSeats = () => {
        let bookedCapacity = 0;
        for (let shift of selectedCenters) {
            for (let centers of shift.centers) {
                bookedCapacity = bookedCapacity + centers.seatCapacity
            }
        }
        setBookedSeats(bookedCapacity)
    }

    const removeCenterByTable = (e: any) => {

        let selectedShiftData = selectedCenters.find(data => data.shiftId === selectedShift)
        if (!selectedShiftData || selectedShiftData.centers.length < 0) {
            return false
        }
        for (const centerCount in selectedShiftData?.centers) {
            if (selectedShiftData.centers[Number(centerCount)].centerId === e.centerId) {
                selectedShiftData.centers.splice(Number(centerCount), 1)
            }
        }
        let newSelectedCenters = selectedCenters.filter(data => data.shiftId != selectedShift)
        newSelectedCenters.push(selectedShiftData);
        setSelectedCenters(newSelectedCenters)
    }

    useEffect(() => {
        // on new seat selection
        calculateSelectedSeats()
    }, [selectedCenters])


    // Using useEffect
    useEffect(() => {
        getCenterCapacity();
        getAvailableCentersInDistByJobId();
        getSeletedCenters();
    }, [])
    
    useEffect(() => {
        getCenterData();
    }, [props.choice])
    
    const getCenterData = () => {
        MappingService.getCenterData(params.jobId, {
            "choice": props.choice
        }).then((res) => {
            if (res.status === 200){
                setSelectedCenters(res.data);
            }
        });
    }

    const renderData = () => {

        return (
            <>
                <div className="border border-1 p-3">

                    <Row>
                        <Col md={6}>
                            <div>
                                <span className="text-muted fw-bold">
                                    District Name:
                                </span>
                                <span className="text-primary ms-1 fw-bold">
                                    {props.mappingCity}
                                </span>
                            </div>

                            <div>
                                <span className="text-muted fw-bold">
                                    District Capacity:
                                </span>
                                <span className="ms-1 fw-bold">
                                    {capacity}
                                </span>
                            </div>

                        </Col>
                        <Col md={6}>
                            <div className="d-flex justify-content-end align-items-center">

                                <XcnButton
                                    variant="warning"
                                    text={
                                        <>
                                            Auto Select Centers
                                            <FontAwesomeIcon icon={faLaptopCode} className="ms-1" />
                                        </>
                                    }
                                    size="sm"
                                    onClick={autoSelectCenters}
                                />
                                <XcnButton
                                    variant="success"
                                    text={
                                        <>
                                            Add Mapping To Booking
                                            <FontAwesomeIcon icon={faSave} className="ms-1" />
                                        </>
                                    }
                                    size="sm"
                                    onClick={saveMappingToChoice}
                                    className="ms-2"
                                />
                            </div>
                            <div className="d-flex justify-content-end align-items-center mt-2">
                                <div className="text-danger fw-bold">
                                    Total Selected
                                    <span className="text-info mx-1">
                                        [all shifts]
                                    </span>
                                    {bookedSeats}
                                </div>
                            </div>


                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Tabs activeKey={selectedShift} id="uncontrolled-tab" className="mb-3" onSelect={(e: any) => setSelectedShift(e)}>
                            {

                                props.shifts.map((data: any) => {
                                    return (
                                        <Tab
                                            eventKey={data._id} title={data.shiftName}
                                            tabClassName="fw-bold"
                                        />
                                    )
                                })
                            }
                        </Tabs>
                    </Row>
                    <Row>
                        <Col>
                        <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="fw-bold xcn-text-12 mt-3">
                                Available Centers in District
                            </Form.Label>
                            <Form.Label className="fw-bold text-primary xcn-text-12 mt-3 xcn-link-pointer" onClick={()=>setShowTable(true)}>
                                View In Table
                            </Form.Label>
                        </div>
                            <Select
                                isMulti
                                options={availableCenters}
                                value={calculateValue(selectedCenters, selectedShift)}
                                onChange={handleSelectChange}
                            />
                        </Col>
                    </Row>
                    <XcnModal
                        show={showTable}
                        handleClose={()=>setShowTable(false)}
                        body={ <MappingCentersTable
                            centers={selectedCenters.find(data => data.shiftId === selectedShift)?.centers}
                            removeCenter={removeCenterByTable}
                        />}
                        heading="Center Mapping For District"
                        size="lg"
                        backdrop="static"
                        centered={true}
                    />

                </div>
            </>
        )
    }

    return (

        selectedShift ?

            renderData() :

            <> Loading Please Wait </>
    )
}
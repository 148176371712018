import React from 'react';
import { Card, Container } from 'react-bootstrap';
import JobCounterStatus from '../../../../components/status/JobCounter.status';
import { JobStatusEnum } from '../../../../enum/jobStatus.enum';


export interface IJobStepInterface {
    stepName: string
    component?: any
}

interface IJobStepCounter {
    steps: IJobStepInterface[],
    selectedStep: number
}

export default function JobStepCounter({ steps, selectedStep }: IJobStepCounter) {

    return (
        <>
            <Container>
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-start align-items-center">
                        {steps.map((data: IJobStepInterface, index: number) => {
                            return (
                                <div key={selectedStep} className="me-4">
                                    <JobCounterStatus 
                                        counter={index+1}
                                        stepName={data.stepName}
                                        status={
                                            index === selectedStep ? 
                                            JobStatusEnum.INPROGRESS : 
                                            index < selectedStep ? 
                                            JobStatusEnum.COMPLETED : 
                                            JobStatusEnum.NOTVISITED
                                        }
                                        key={selectedStep}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </Card>
            </Container>
        </>
    )
}
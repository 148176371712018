import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import CorpService from '../../../services/corp.service';
import CreateCorp from './CreateCorp';
import ViewAllCorpUsers from './ViewAllCorpUsers';

export default function ViewCorp() {

  const [corpData, setCorpData] = useState<any[]>([]);

  const getAllCorpUsers = async () => {
    await CorpService.getAllCorps().then((res) => {
      if (res.status === 200) {
        setCorpData(res.data);
      }
    });
  };

  useEffect(() => {
    getAllCorpUsers();
  }, []);

  return (
    <>
      <Container className="xcd-container-large xcn-conatiner">
        <div className="mt-4">
          <CreateCorp corpData={corpData} setCorpData={setCorpData} reload={getAllCorpUsers} />
        </div>
        <div>
          <ViewAllCorpUsers corpData={corpData} reload={getAllCorpUsers} />
        </div>
      </Container>
    </>
  );
}


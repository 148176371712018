import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, FloatingLabel, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CorpService from '../../../services/corp.service';
import PublicService from '../../../services/public.service';

export default function CreateCorp(props: any) {
  const [name, setName] = useState<any>();
  const [password, setPassword] = useState<string>()
  const [email, setEmail] = useState<string>()


  const onAddUserClick = async () => {
    if (!password || !email) {
      toast.warning("Email and Passwords are required")
      return false
    }
    await PublicService.createCorpUser(name, email, password).then(res => {
      if (res.status === 200) {
        toast.success(res.data.msg);
        props.reload();
        setName("");
        setPassword("")
        setEmail("");
      }
      else {
        toast.warning(res.data.msg || res.data.err)
      }
    }).catch(e => {
      console.error(e)
    })

  }


  //  const getAllSearchedCorp = async (search: string) => {
  //    console.log(search);
  //    await CorpService.searchCorp(search)
  //      .then((res) => {
  //        if (res.status === 200) {
  //          console.log(res.data, "data");
  //          props.setCorpData(res.data.centers);
  //        }
  //      })
  //      .catch((err) => {
  //        toast.error("please Search By Center");
  //      });
  //  };



  return (
    <>
      <Card className="xcn-card">
        <h5 className="fw-bold text-info">Add New Corp User</h5>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <Form.Group>
              <Form.Label className="xcn-text-12" >Email address</Form.Label>
              <Form.Control
                type="email"
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='ms-2'>
              <Form.Label className="xcn-text-12" >Corp/Org Name</Form.Label>
              <Form.Control
                type="text"
                onChange={(e: any) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className='ms-2'>
              <Form.Label className="xcn-text-12" >
                Password
              </Form.Label>
              <Form.Control
                type="password"
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </Form.Group>

          </div>

        </div>
        <div className='mt-3'>
          <Button
            variant="primary"
            onClick={onAddUserClick}
            size="sm"
          >
            Add New User{" "}
            <FontAwesomeIcon icon={faUserPlus} className="ms-2" />
          </Button>
        </div>
      </Card>
    </>
  );
}
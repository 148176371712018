import React from 'react';
import { Card, Container } from 'react-bootstrap';

export default function ViewExamInfo(){
    return (
        <>
            <Container>
                <Card className="xcn-card mt-3">
                        hello
                </Card>
            </Container>
        </>
    )
}
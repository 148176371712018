import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, Container, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import XcnButton from '../../../../../components/XcnButton/XcnButton';
import ExamService from '../../../../../services/exam.service';
import MappingService from '../../../../../services/mapping.service';

interface ISelectCities {
    setSelectedStep: any
    selectedStep: number
}

interface IUniqueDistricts {
    sheetUniqueCities: any[]
    systemUniqueCities: any[]
}

export interface ICityMappingData {
    sheet: string,
    exmcen: string[]
}

export default function SelectCities(props: ISelectCities) {

    const params: any = useParams();

    const [uniqueDistricts, setUniqueDistricts] = useState<IUniqueDistricts>()

    const [cityMapping, setCityMapping] = useState<ICityMappingData[]>([])

    const [mappingData, setMappingData] = useState<any>();


    const getSheetDistrict = async () => {
        await ExamService.getSheetDistrict(params.jobId).then(res => {
            if (res.status === 200) {
                const systemUniqueCities = res.data.data.systemUniqueCities.map((data: any, index: number) => {
                    return {
                        value: data,
                        label: data
                    }
                })
                setUniqueDistricts({
                    systemUniqueCities: systemUniqueCities,
                    sheetUniqueCities: res.data.data.sheetUniqueCities
                })
            }
        })
    }

    const getFieldMappingByJobId = async () => {
        await MappingService.getFieldMappingForJobId(params.jobId).then(res=>{
            if(res.status === 200){
                setCityMapping(res.data.cityMapping)
            }
        })
    }

    const handleCityMapping = (e: any, cityFromSheet: any) => {
        console.log(e)
        const cityMappingCurrent = cityMapping;
        const valuesOtherThanSelected = cityMappingCurrent?.filter(data => data.sheet != cityFromSheet);
        const selectedCities = e.map((data: any) => {
            return data.value
        });
        valuesOtherThanSelected.push({
            sheet: cityFromSheet, 
            exmcen: selectedCities
        })

        setCityMapping(valuesOtherThanSelected);
    }


    const onNextClick = async () => {
        await MappingService.updateCityMapping(params.jobId, cityMapping).then(res=>{
            if(res.status === 200){
                toast.success("Updated City Mapping")
                props.setSelectedStep(props.selectedStep + 1)
            }
        })
    }

    const calculateValue = (city:string, mappingData: any) => {
        for(const mapping of mappingData){
            if(mapping.sheet === city){
                return mapping.exmcen.map((data:any)=>{
                    return {
                        label: data,
                        value: data
                    }
                })
                
            }
        }
    }


    useEffect(() => {
        getSheetDistrict();
        getFieldMappingByJobId()
    }, [])


    return (
        <>
            <Container>
                <Card className="xcn-card mt-3">
                    <h5 className="text-secondary fw-bold">
                        Cities Mapping
                    </h5>
                    <Table borderless striped className="mt-3">
                        <thead>
                            <tr className="text-secondary">
                                <th>#</th>
                                <th>City/District Name (From Sheet)</th>
                                <th>Map to District (To System)</th>
                            </tr>
                        </thead>
                        <tbody>

                            {uniqueDistricts && uniqueDistricts.sheetUniqueCities && uniqueDistricts.sheetUniqueCities.length > 0 ?
                                uniqueDistricts.sheetUniqueCities.map((data: any, index: number) => {
                                    return (
                                        <tr className="xcn-table-tr" key={index}>
                                            <td className="fw-bold">
                                                {index + 1}
                                            </td>
                                            <td className="fw-bold xcn-text-14">
                                                {data}
                                            </td>
                                            <td>
                                                <Select
                                                    isMulti={true}
                                                    options={uniqueDistricts.systemUniqueCities}
                                                    value={calculateValue(data,cityMapping)}
                                                    onChange={(e) => handleCityMapping(e, data)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                                : <tr>
                                    <td colSpan={3}>
                                        No Available Cities to Map
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-between align-items-center">
                        <XcnButton
                            text={<>
                                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                                Back
                            </>}
                            variant="danger"
                            onClick={() => props.setSelectedStep(props.selectedStep - 1)}
                        />
                        <XcnButton
                            text={<>
                                Save and Next
                                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                            </>}
                            variant="success"
                            onClick={() => onNextClick()}
                        />
                    </div>
                </Card>
            </Container>
        </>
    )
}
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { createDateFormat } from '../../helpers/time.helper';
import BooleanStatus from '../status/BooleanStatus';

interface IViewExamTable {
    exams: any[]
}

export default function ViewExamTable(props: IViewExamTable) {

    const navigate = useNavigate();

    return (
        <div>
            <Table borderless striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Exam Name</th>
                        <th>Exam Date</th>
                        <th>Shift Count</th>
                        <th>Admit Card</th>
                        <th>Center(s) Count</th>
                        <th>Updated At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.exams.length > 0 ?
                            props.exams.map((data: any, index: number) => {
                                return (
                                    <tr className="xcn-table-tr" key={data._id}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td className="fw-bold xcn-text-12">
                                            {data.examName}
                                        </td>
                                        <td className="fw-bold xcn-text-12 text-secondary">
                                            {createDateFormat(data.startDate) +" - "+ createDateFormat(data.endDate)}
                                        </td>
                                        <td className="font-monospace xcn-text-12 text-info">
                                            {data.shifts.length}
                                        </td>
                                        <td>
                                            <BooleanStatus 
                                                status ={data.admitCardGenerated}
                                                value={{ true: "Generated", false: "Not Generated"}}
                                            />
                                        </td>
                                        <td className="font-monospace xcn-text-12 text-info">
                                            {data.centers.length}
                                        </td>
                                        <td className="fw-bold xcn-text-12 text-secondary">
                                            {createDateFormat(data.updatedAt)}
                                        </td>
                                        <td>
                                            <FontAwesomeIcon icon={faEye} 
                                                className="text-info xcn-link-pointer" 
                                                onClick={()=> navigate("/examdash/"+data._id+"/")}
                                                />
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={6} className="text-danger fw-bold">
                                    No Exams Registered
                                </td>
                            </tr>
                    }

                </tbody>
            </Table>
        </div>
    )
}
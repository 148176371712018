import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import MappingService from '../../../../../../services/mapping.service';
import SelectedCenterDist from './SelectedCenterDist';

interface ISelectedCenterChoice {
    fieldMapping: any
    requiredSeats: number
    shifts: any
}

interface IChoicesavedCenters {
    centerData: [{
        shiftId: string
        centers: any[]
    }]
    distName: string
}

export default function SelectedCenterChoice(props: ISelectedCenterChoice) {

    const [bookedSeats, setBookedSeats] = useState<number>(0);

    const params: any = useParams();

    const [choiceSavedCenters, setChoiceSavedCenters] = useState<IChoicesavedCenters[]>([]);


    const submitMapping = async () => {

        let shiftData: any[] = [];

        // find all shifts and perform the action shift wise
        for (let shift of props.shifts) {
            console.log(shift._id);
            let centersArr: any[] = [];
            let seatCapacity: number = 0;
            // merge get all choices (i.e. all dist under the choice)
            for (let choice of choiceSavedCenters) {
                const centerData = choice.centerData.filter((data) => data.shiftId === shift._id)
                // merge centers with old centers 
                for (let centers of centerData) {
                    centersArr = centersArr.concat(centers.centers);
                    // only to calculate capacity 
                    for (const center of centers.centers) {
                        seatCapacity = seatCapacity + center.seatCapacity
                    }
                }
            }
            shiftData.push({
                shiftId: shift._id,
                center: centersArr,
                seatCapacity: seatCapacity
            })
        }

        const payload = {
            choice: props.fieldMapping.sheet,
            centerData: shiftData
        }

        await MappingService.saveCenterSelectionDistrictShiftWise(params.jobId, params.examId, payload).then(res => {
            if (res.status === 200) {
                toast.success("Mapping Saved!")
            }
        })

    }

    const handleSetChoiceSavedCenters = (centerData: any, distName: string) => {
        const newChoices = choiceSavedCenters.filter((choice: any) => choice.distName != distName)
        setChoiceSavedCenters([...newChoices, {
            centerData: centerData,
            distName: distName
        }])
        toast.success(<span className="fw-bold xcn-text-12">
            Updated District Centers for booking
        </span>)

    }

    useEffect(() => {

        let bookedCount = 0;

        for (const dist of choiceSavedCenters) {
            for (const shift of dist.centerData) {
                for (const center of shift.centers) {
                    bookedCount = bookedCount + center.seatCapacity
                }
            }
        }
        setBookedSeats(bookedCount)

    }, [handleSetChoiceSavedCenters])

    return (
        <>
            <div className='d-flex justify-content-between'>
                <div>
                    <div>
                        <span className="text-muted fw-bold">
                            Reserved Seats for {props.fieldMapping.sheet}:
                        </span>
                        <span className="text-info ms-1 fw-bold">
                            {bookedSeats}
                        </span>
                        <div>
                            <span className="text-muted fw-bold">
                                Required Seats for {props.fieldMapping.sheet}:
                            </span>
                            <span className="text-danger ms-1 fw-bold">
                                {props.requiredSeats}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center ms-2 my-2'>
                    <Button variant="secondary" size="sm" onClick={submitMapping}>Submit Center Mapping for {props.fieldMapping.sheet}</Button>
                </div>
            </div>
            {
                props.fieldMapping && props.fieldMapping.exmcen && props.fieldMapping.exmcen.length > 0 ? props.fieldMapping.exmcen.map((data: any, index: number) => {
                    return (
                        <div className="my-2" key={index}>
                            <SelectedCenterDist
                                mappingCity={data}
                                requiredSeats={props.requiredSeats}
                                bookedSeats={bookedSeats}
                                shifts={props.shifts}
                                setChoiceSavedCenters={handleSetChoiceSavedCenters}
                                choice={props.fieldMapping.sheet}
                            />
                        </div>

                    )
                }) : <>
                    Loading Data
                </>
            }
        </>
    )
}
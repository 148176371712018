import { faKey, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, FloatingLabel, Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AdminService } from '../../../services/admin.service';
import PublicService from '../../../services/public.service';
import Select from 'react-select';
import { options } from '../../CenterDash/CenterDashStats';
import CorpService from '../../../services/corp.service';
import CenterService from '../../../services/center.service';
import urls from '../../../services/urls';

interface ICreateApiKey {
    reload: any
}

export default function CreateApiKey(props: ICreateApiKey) {

    const [role, setRole] = useState<any>({});

    const [corps, setCorps] = useState<any[]>([]);
    const [centers, setCenters] = useState<any[]>([]);

    const [selectedCorp, setSelectedCorp] = useState<any>({});
    const [selectedCenter, setSelectedCenter] = useState<any>({});

    const getAllCorpUsers = async () => {
        await CorpService.getAllCorps().then(res => {
            if (res.status === 200) {
                setCorps(res.data);
            }
        })
    }

    const getAllCenters = async () => {
        await CenterService.getAllCenters().then(res => {
            if (res.status === 200) {
                setCenters(res.data);
            }
        })
    }

    useEffect(() => {
        getAllCenters();
        getAllCorpUsers();
    }, [])


    const createKey = async() => {
        let url = urls.admin.createApiKey + "/" + role.value;
        await AdminService.createApiKey(url, selectedCenter.value, selectedCorp.value).then(res => {
            if(res.status == 200){
                toast.success("Created successfully")
                props.reload();
            }
        }).catch(e => {
            toast.error(e.response.data)
            console.error(e)
        })
    }

    const renderFirstSelection = () => {
        if(!role || !role.value){
            return false
        }
        switch (role.value) {
            case 'corp': {
                return (
                    <>
                        <Col md={4}>
                            <Form.Label className="fw-bold xcn-text-10 text-secondary">Select Corp</Form.Label>
                            <Select
                                options={corps.map((corp: any) => {
                                    return {
                                        value: corp._id,
                                        label: corp.orgName
                                    }
                                })
                                }
                                onChange={setSelectedCorp}
                                value={selectedCorp}
                            />
                        </Col>
                    </>
                )
            }
            case 'center': {
                return (
                    <>
                        <Col md={4}>
                            <Form.Label className="fw-bold xcn-text-10 text-secondary">Select Center</Form.Label>
                            <Select
                                options={centers.map((center: any) => {
                                    return {
                                        value: center._id,
                                        label: center.centerId + ' - ' + center.name
                                    }
                                })
                                }
                                onChange={setSelectedCenter}
                                value={selectedCenter}
                            />
                        </Col>
                    </>
                )
            }
        }
    }

    const renderThirdSelection = () => {
        if(!role || !role.value){
            return false
        }
        if (role.value === 'center' && !selectedCenter.value) {
            return false
        }
        if (role.value === 'corp' && !selectedCorp.value) {
            return false
        }


        return (
            <Row className='mt-3'>
                <Col>
                <Button size="sm" onClick={createKey}>Create Key</Button>

                </Col>
            </Row>
        )
    }

    return (
        <>
            <Card className='xcn-card'>
                <h5 className="fw-bold text-info">
                    Add New API Key
                </h5>
                <Row>
                    <Col md={4}>
                        <Form.Label className="fw-bold xcn-text-10 text-secondary">Role Type</Form.Label>
                        <Select
                            options={[
                                { value: 'corp', label: 'Corp' },
                                { value: 'center', label: 'Center' },
                                { value: 'admin', label: 'Admin' }
                            ]}
                            onChange={(e: any) => setRole(e)}
                            value={role}
                            isClearable={true}
                        />
                    </Col>
                    {renderFirstSelection()}
                    {renderThirdSelection()}

                </Row>
            </Card>
        </>
    )
}
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CenterApprovals from "../../../../components/Centers/CenterApprovals";
import InputBox from "../../../../components/Input/InputBox";
import AddApprovalModal from "../../../../components/Modals/AddApproval.modal";
import CenterService from "../../../../services/center.service";
import MyUploader from "../../../../components/Dropzone/dropzone";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { UploadService } from "../../../../services/upload.service";
import Select from "react-select"

interface INetwork {
  networkTopology?: string;
  networkKitsAvailable?: boolean;
  totalLansAvailable?: any;
  totalNodesAvailable?: any;
  switchType?: string;
  bufferNetworkSwitchAvailable?: boolean;
  LanConnectivity?: string;
  LanProperlyConnected?: boolean;
  PatchCardCrimping?: boolean;
  totalSwitchesAvailable?: any;
  internetInAccessLayerSwitches?: boolean;
  extraLanUsedOutOfExamCenter?: boolean;
  blankRJ45Connector?: boolean;
  separationOfExamLan?: boolean;
  internetDetails?: string;
  lanDiagram?: string;



  typeOfNetwork?: string,
  ispName?: string,
  anyProxyOrFirewallAvailable?: boolean,
  canDhcpServerBeSwitchedOffIfAvailable?: boolean,
  coreSwitchedSpeed?: string,
  typeOfNetworkLanWan?: string,
  lanCableAvailableForAllPCsThoughIoPort?: boolean,
  singleOrMultipleLanVlan?: string,
  networkSpeed?: string,
  coreSwitchSpeed?: string,
  switchMakeModelNo?: string,
  switchTypeManageableNonManagiable?: string,
  canWirelessRouterBeSwitchedOffIfAvailable?: boolean
}

export default function NetworkDetails() {
  const params: any = useParams();

  const [editEnabled, setEditEnabled] = useState<boolean>(false);

  const fileSelectedHandler = async (e: any, name: string) => {
    const fd = new FormData();
    // let arr=[];
    for (let i of e) {
      fd.append("upload", i);
    }
    await UploadService.uploadFileToS3(fd).then((res: any) => {
      if (res.status === 200) {
        setNetworkData({ ...networkData, [name]: res.data[0] });
        toast.success(`File(s) uploaded`);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleSelectValue = (e: any, name: string) => {
    setNetworkData({ ...networkData, [name]: e.value })
  }

  const [networkData, setNetworkData] = useState<INetwork>();

  const network_topology = [
    {
      label: 'Star',
      value: "Star"
    },
    {
      label: 'Bus',
      value: "Bus"
    },
    {
      label: 'Hybrid',
      value: "Hybrid"
    },
  ]

  const switches_types = [
    {
      label: 'MBPS',
      value: "MBPS"
    },
    {
      label: 'GBPS',
      value: "GBPS"
    },
  ]

  const network_types = [
    {
      label: 'LAN',
      value: "LAN"
    },
    {
      label: 'WAN',
      value: "WAN"
    },
  ]

  const switches_type = [
    {
      label: 'Manageable',
      value: "Manageable"
    },
    {
      label: 'Non-Manageable',
      value: "Non-Manageable"
    },
  ]

  const lan_connectivity = [
    {
      label: 'Cat 5E',
      value: "Cat 5E"
    },
    {
      label: 'CAT 6',
      value: "CAT 6"
    },
  ]

  const lan_types = [
    {
      label: "Single",
      value: "SIngle"
    }, {
      label: "Multiple",
      value: "Multiple"
    }
  ]

  const handleChange = (e: any) => {
    let newData: any;
    if (e.target.type === "checkbox") {
      newData = { ...networkData, [e.target.name]: e.target.checked };
    } else {
      newData = { ...networkData, [e.target.name]: e.target.value };
    }
    setNetworkData(newData);
  };

  function removeEmpty(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
  }

  const getCenterDetails = async () => {
    await CenterService.getCenterDetails(params.centerId)
      .then((res) => {
        console.log(res.data);
        setNetworkData(res.data.networkDetails);
        setEditEnabled(false)
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message}`
        );
      });
  };

  useEffect(() => {
    getCenterDetails();
  }, []);

  useEffect(() => {
    console.log(networkData);
  }, [networkData]);

  const updateCenterData = async () => {
    await CenterService.updateCenterData(params.centerId, {
      networkDetails: removeEmpty(networkData),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          setEditEnabled(false);
          getCenterDetails();
        }
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message}`
        );
      });
  };

  return (
    <>
      <Container>
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-secondary fw-bold">
              Network & IT Infrastructure
            </h5>
            <div>
              {editEnabled ? (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      Save
                    </>
                  }
                  variant="success"
                  onClick={updateCenterData}
                />
              ) : (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faEdit} className="me-2" />
                      Edit
                    </>
                  }
                  variant="warning"
                  onClick={() => setEditEnabled(true)}
                />
              )}
            </div>
          </div>
          {/* <Row>
            <Col md={6}>
              <InputBox
                type="text"
                name="networkTopology"
                label="Network Topology(Star, Bus, Hybrid)"
                disabled={!editEnabled}
                onChange={handleChange}
                defaultValue={networkData?.networkTopology}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="number"
                name="totalLansAvailable"
                label="Total LANs available and total used"
                disabled={!editEnabled}
                onChange={handleChange}
                defaultValue={networkData?.totalLansAvailable}

              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <InputBox
                type="number"
                name="totalNodesAvailable"
                label="Total nodes available and used in exams"
                disabled={!editEnabled}
                defaultValue={networkData?.totalNodesAvailable}

                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="switchType"
                label="Switches Types (mbps/gbps) managed/unmanaged"
                disabled={!editEnabled}
                defaultValue={networkData?.switchType}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <InputBox
                type="text"
                name="LanConnectivity"
                label="LAN Connectivity (CAT 5E/CAT 6) for Server"
                defaultValue={networkData?.LanConnectivity}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="number"
                name="totalSwitchesAvailable"
                label="Total number of switches available(Distribution & Access Layer Switches)"
                disabled={!editEnabled}
                defaultValue={networkData?.totalSwitchesAvailable}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <InputBox
                type="text"
                name="internetDetails"
                label="Internet Details (Speed & Provider details)"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
          </Row> */}

          <Row>
            <Col md={6}>
              <InputBox
                type="number"
                name="totalLansAvailable"
                label="Total LANs available and total used"
                disabled={!editEnabled}
                onChange={handleChange}
                defaultValue={networkData?.totalLansAvailable}

              />
            </Col>
            <Col md={6}>
              <InputBox
                type="number"
                name="totalNodesAvailable"
                label="Total nodes available and used in exams"
                disabled={!editEnabled}
                defaultValue={networkData?.totalNodesAvailable}

                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <InputBox
                type="number"
                name="totalSwitchesAvailable"
                label="Total number of switches available(Distribution & Access Layer Switches)"
                disabled={!editEnabled}
                defaultValue={networkData?.totalSwitchesAvailable}
                onChange={handleChange}
              />
            </Col>
            <Col>
              <InputBox
                type="text"
                name="internetDetails"
                label="Internet Details (Speed & Provider details)"
                disabled={!editEnabled}
                onChange={handleChange}
                defaultValue={networkData?.internetDetails}
              />
            </Col>
          </Row>
          {/* addedd */}

          <Row className="mt-3">
            <Col md={6}>
              <InputBox
                type="text"
                name="ispName"
                label="ISP name"
                disabled={!editEnabled}
                defaultValue={networkData?.ispName}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="networkSpeed"
                label="Network Speed"
                disabled={!editEnabled}
                defaultValue={networkData?.networkSpeed}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <InputBox
                type="text"
                name="coreSwitchSpeed"
                label="Core switched speed"
                defaultValue={networkData?.coreSwitchSpeed}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="switchMakeModelNo"
                label="Switch make model no."
                disabled={!editEnabled}
                defaultValue={networkData?.switchMakeModelNo}
                onChange={handleChange}
              />
            </Col>
          </Row>
          {/* <Row className="mt-3">
            <Col>
              <InputBox
                type="text"
                name="switchMakeModelNo"
                label="Switch make model no."
                disabled={!editEnabled}
                defaultValue={networkData?.switchMakeModelNo}
                onChange={handleChange}
              />
            </Col>
          </Row> */}


          <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Switches Types (mbps/gbps)</Form.Label>
                <Select
                  options={switches_types}
                  onChange={(e: any) => handleSelectValue(e, "switchType")}
                  value={[{ label: networkData?.switchType, value: networkData?.switchType }]}
                  isDisabled={!editEnabled}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Type of Network</Form.Label>
                <Select
                  options={network_types}
                  onChange={(e: any) => handleSelectValue(e, "typeOfNetwork")}
                  value={[{ label: networkData?.typeOfNetwork, value: networkData?.typeOfNetwork }]}
                  isDisabled={!editEnabled}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>Switch type Manageable/Non-Manageable</Form.Label>
                <Select
                  options={switches_type}
                  onChange={(e: any) => handleSelectValue(e, "switchTypeManageableNonManagiable")}
                  value={[{ label: networkData?.switchTypeManageableNonManagiable, value: networkData?.switchTypeManageableNonManagiable }]}
                  isDisabled={!editEnabled}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Network Topology</Form.Label>
                <Select
                  options={network_topology}
                  onChange={(e: any) => handleSelectValue(e, "networkTopology")}
                  value={{ label: networkData?.networkTopology, value: networkData?.networkTopology }}
                  isDisabled={!editEnabled}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>LAN Connectivity (CAT 5E/CAT 6) for Server</Form.Label>
                <Select
                  options={lan_connectivity}
                  onChange={(e: any) => handleSelectValue(e, "LanConnectivity")}
                  value={{ label: networkData?.LanConnectivity, value: networkData?.LanConnectivity }}
                  isDisabled={!editEnabled}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Single or Multiple LAN/WAN</Form.Label>
                <Select
                  options={lan_types}
                  onChange={(e: any) => handleSelectValue(e, "singleOrMultipleLanVlan")}
                  value={{ label: networkData?.singleOrMultipleLanVlan, value: networkData?.singleOrMultipleLanVlan }}
                  isDisabled={!editEnabled}
                />
              </Form.Group>
            </Col>

          </Row>

          <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
            <span>
              <Form.Check
                inline
                type="switch"
                id="networkKitsAvailable"
                label="Network Kits Available"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={networkData?.networkKitsAvailable}
                name="networkKitsAvailable"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="bufferNetworkSwitchAvailable"
                label="Buffer Network Switch available"
                checked={networkData?.bufferNetworkSwitchAvailable}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="bufferNetworkSwitchAvailable"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="LanProperlyConnected"
                label="LAN cabling properly connected"
                checked={networkData?.LanProperlyConnected}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="LanProperlyConnected"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="PatchCardCrimping"
                label="Patch Card Crimping done properly"
                checked={networkData?.PatchCardCrimping}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="PatchCardCrimping"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="internetInAccessLayerSwitches"
                label="Internet available in Distribution/Access Layer switches"
                checked={networkData?.internetInAccessLayerSwitches}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="internetInAccessLayerSwitches"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="extraLanUsedOutOfExamCenter"
                label="Any extra LAN cables emerging out of the center from Lab"
                checked={networkData?.extraLanUsedOutOfExamCenter}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="extraLanUsedOutOfExamCenter"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="blankRJ45Connector"
                label="Unused ports plugged with blank RJ45 connectors"
                checked={networkData?.blankRJ45Connector}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="blankRJ45Connector"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="separationOfExamLan"
                label="Physical Separation of exam LAN from any other non-exam LAN"
                checked={networkData?.separationOfExamLan}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="separationOfExamLan"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>


            {/* addedd */}

            <span>
              <Form.Check
                inline
                type="switch"
                id="anyProxyOrFirewallAvailable"
                label="Any proxy or firewall available"
                checked={networkData?.anyProxyOrFirewallAvailable}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="anyProxyOrFirewallAvailable"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="canDhcpServerBeSwitchedOffIfAvailable"
                label="Can DHCP server be switched off (If Available)"
                checked={networkData?.canDhcpServerBeSwitchedOffIfAvailable}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="canDhcpServerBeSwitchedOffIfAvailable"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="lanCableAvailableForAllPCsThoughIoPort"
                label="Lan cable available for all PC's though I/O port"
                checked={networkData?.lanCableAvailableForAllPCsThoughIoPort}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="lanCableAvailableForAllPCsThoughIoPort"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="canWirelessRouterBeSwitchedOffIfAvailable"
                label="Can wireless router be switched off (If Available)"
                checked={networkData?.canWirelessRouterBeSwitchedOffIfAvailable}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="canWirelessRouterBeSwitchedOffIfAvailable"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
          </div>

          {/* <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
            <span>
              <Form.Check
                inline
                type="switch"
                id="networkKitsAvailable"
                label="Network Kits Available"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={networkData?.networkKitsAvailable}
                name="networkKitsAvailable"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="bufferNetworkSwitchAvailable"
                label="Buffer Network Switch available"
                checked={networkData?.bufferNetworkSwitchAvailable}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="bufferNetworkSwitchAvailable"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="LanProperlyConnected"
                label="LAN cabling properly connected"
                checked={networkData?.LanProperlyConnected}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="LanProperlyConnected"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="PatchCardCrimping"
                label="Patch Card Crimping done properly"
                checked={networkData?.PatchCardCrimping}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="PatchCardCrimping"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="internetInAccessLayerSwitches"
                label="Internet available in Distribution/Access Layer switches"
                checked={networkData?.internetInAccessLayerSwitches}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="internetInAccessLayerSwitches"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="extraLanUsedOutOfExamCenter"
                label="Any extra LAN cables emerging out of the center from Lab"
                checked={networkData?.extraLanUsedOutOfExamCenter}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="extraLanUsedOutOfExamCenter"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="blankRJ45Connector"
                label="Unused ports plugged with blank RJ45 connectors"
                checked={networkData?.blankRJ45Connector}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="blankRJ45Connector"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="separationOfExamLan"
                label="Physical Separation of exam LAN from any other non-exam LAN"
                checked={networkData?.separationOfExamLan}
                // checked={props.updateCenterFacilities?.drinkingWater}
                name="separationOfExamLan"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
          </div> */}

          <Row>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-4 text-secondary fw-bold w-100">
                <h6>
                  Network Architecture Diagram
                  {/* <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  /> */}
                </h6>
                {editEnabled === false && networkData?.lanDiagram ? <>
                  <Image src={networkData?.lanDiagram} width="100%" />
                </> :

                  <Row>
                    <Col md={6}>
                      <Image src={networkData?.lanDiagram} width="100%" />
                    </Col>
                    <Col md={6}>
                      <MyUploader
                        fileSelectedHandler={(e: any) =>
                          fileSelectedHandler(e, "lanDiagram")
                        }
                      />
                    </Col>
                  </Row>}
              </div>
            </div>
          </Row>
        </Card>
      </Container>
    </>
  );
}

import { faEllipsisVertical, faEye, faShare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomToggle from '../../../components/Menu/CustomMenu';
import CorpService from '../../../services/corp.service';
import ResetPasswordModal from '../../../components/Modals/ResetPassword.modal';

export default function ViewAllCorpUsers(props: any) {

    const navigate = useNavigate();
    const [showPasswordModal, setShowPasswordModal] = useState<any>(undefined);

    const handleDelete = async (id: any) => {
        await CorpService.removeCorpUser(id)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Removed successfully");
                    props.reload();
                }
            })
            .catch((err) => {
                toast.error(err.response.data)
            });
    }

    const resetPassword = async (corpId: any) => {
        await CorpService.resetPassword(corpId).then((res: any) => {
          if (res.status === 200) {
            setShowPasswordModal(res.data.password)
          }
        })
      }


    return (
        <Card className="xcn-card mt-4">
            <Table borderless striped>
                <thead>
                    <tr>
                        <th>Sr No.</th>
                        <th>Organisation Name</th>
                        <th>Email</th>
                        <th>Number of Centers</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {props.corpData.length > 0 ?
                        props.corpData.map((data: any, index: number) => {
                            return (
                                <tr className="xcn-table-tr" key={data._id}>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>
                                        {data.orgName}
                                    </td>
                                    <td>
                                        {data.orgEmail}
                                    </td>
                                    <td>
                                        {data.centers}
                                    </td>
                                    <td>
                                        {/* <FontAwesomeIcon
                                            icon={faEye}
                                            className="text-info"
                                            onClick={() => navigate("/dashboard/corporates/view/" + data._id)}
                                        />
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            className="text-danger ms-4"
                                            onClick={() => handleDelete(data._id)}
                                        /> */}



                                        <Dropdown>
                                            <Dropdown.Toggle
                                                as={CustomToggle}
                                                id="dropdown-custom-components"
                                            >
                                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => navigate("/dashboard/corporates/view/" + data._id)}>
                                                    <FontAwesomeIcon
                                                        icon={faEye}
                                                        className="text-info xcn-link-pointer"
                                                    />
                                                    <span className="fw-bold text-secondary fs-12 ms-2">
                                                        View
                                                    </span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        resetPassword(data._id);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faShare}
                                                        className="text-info xcn-link-pointer"
                                                    />
                                                    <span className="fw-bold text-secondary fs-12 ms-2">
                                                        Reset Password
                                                    </span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        handleDelete(data._id);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        className="text-danger xcn-link-pointer"
                                                    />
                                                    <span className="fw-bold text-secondary fs-12 ms-2">
                                                        Delete
                                                    </span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </td>
                                </tr>
                            )
                        })

                        : <tr>
                            <td colSpan={5}>
                                No Corporates Registered
                            </td>
                        </tr>}
                </tbody>
            </Table>
            <ResetPasswordModal
                show={showPasswordModal}
                setShow={setShowPasswordModal}
            />
        </Card>
    )
}
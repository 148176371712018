import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { BrowserRouter } from "react-router-dom";
import MainRouter from './router/MainRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/card.css';
import './assets/css/container.css';
import "react-datetime/css/react-datetime.css";
import './theme.scss'


function App() {
  return (
    <div className="App">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
      <BrowserRouter>
        <MainRouter />{/*all route in one file  */}
      </BrowserRouter>
    </div>
  );
}

export default App;

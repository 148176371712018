import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import MyUploader from "../Dropzone/dropzone";
import { UploadService } from "../../services/upload.service";
import { toast } from "react-toastify";
import CenterService from "../../services/center.service";
import { preProcessFile } from "typescript";


interface IAddLabModal {
  show: boolean;
  handleClose: any;
  corpId: string;
  reload: any,
}

interface IInitialValue {
  _centerId: string;
  labName: string;
  buildingName: string;
  floorNumber: string;
  powerBackup: string;
  washroom: boolean;
  labPhoto: [];
}

export default function AddLabModal(props: IAddLabModal) {
  // adminuser/centers/createNewLab
  const params: any = useParams();

  const [addLabDetails, setAddLabDetails] = useState<IInitialValue>({
    _centerId: params.centerId,
    labName: "",
    buildingName: "",
    floorNumber: '',
    powerBackup: "true",
    washroom: true,
    labPhoto: []
  });

  const handleInputChange = (e: any) => {
    if (e.target.name === 'washroom') {
      setAddLabDetails({ ...addLabDetails, [e.target.name]: e.target.value === 'yes' ? true : false });
    }
    else {
      setAddLabDetails({ ...addLabDetails, [e.target.name]: e.target.value });
    }
  }

  const addLab = async () => {
    await CenterService.createNewLab(addLabDetails).then((res: any) => {
      if (res.status === 200) {
        toast.success("Lab Added Success");
        props.handleClose();
        props.reload();
      }
      else {
        toast.error(res.message)
      }
    })
  }

  const fileSelectedHandler = async (e: any) => {
    const fd = new FormData();
    // let arr=[];
    for (let i of e) {
      fd.append("upload", i);
    }
    await UploadService.uploadFileToS3(fd).then((res: any) => {
      if (res.status === 200) {
        setAddLabDetails({ ...addLabDetails, labPhoto: res.data });
        toast.success(`${res.data.length} File(s) uploaded`)
      }
      else {
        toast.error("Something went wrong")
      }

    })
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Register New Lab</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FloatingLabel
              controlId="floatingInput"
              label="Lab Name"
              className="mb-3"
            >
              <Form.Control type="text" name="labName" onChange={(e) => handleInputChange(e)} />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Building Name"
              className="mb-3"
            >
              <Form.Control type="text" name="buildingName" onChange={(e) => handleInputChange(e)} />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Number of floor"
              className="mb-3"
            >
              <Form.Control type="number" name="floorNumber" onChange={(e) => handleInputChange(e)} />
            </FloatingLabel>

            <div>
              <h6>Photos ({`${addLabDetails.labPhoto.length} images uploaded`} )</h6>
              <MyUploader fileSelectedHandler={fileSelectedHandler} />
            </div>
          </div>
          <Button className="primary"
            onClick={addLab}
          >Add Lab</Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

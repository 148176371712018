import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../interfaces/IRouter';
import ViewCorp from '../../views/Corporate/ViewCorp/ViewCorp';
import ViewCorpDetails from '../../views/Corporate/ViewCorpDetails/ViewCorpDetails';

export const corpBaseUrl = "/dashboard/corporates/";

const corpRoutes: IRouter[] = [
    {
        path: "view",
        element: <ViewCorp/>,
        navbarShow: true,
        activeIcon: faHome,
        name: "View All"
    },
    {
        path: "view/:corpId",
        element: <ViewCorpDetails/>,
        navbarShow: false,
        activeIcon: faHome,
        name: "CorpDetails"
    },
    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound"
    }
    
    ]

export default corpRoutes;
import React, { useState } from 'react';
import { Container, Card, Row, Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputBox from '../../components/Input/InputBox';
import XcnButton from '../../components/XcnButton/XcnButton';
import ExamService from '../../services/exam.service';
import DateTime from "react-datetime";


export default function AddExam() {


    const navigate = useNavigate();

    const [addExamDetails, setAddExamDetails] = useState<any>({});

    const handleInputChange = (e: any) => {
        setAddExamDetails({...addExamDetails, [e.target.name]: e.target.value});
    }

    const handleDateChange = (name: string, e: any) => {
        setAddExamDetails({...addExamDetails, [name]: e})
    }


    const onAddExamClick = async() => {
        await ExamService.createExam(addExamDetails).then(res => {
            if(res.status=== 200){
                toast.success("Exam Created!")
                setAddExamDetails({})
                navigate("/dashboard/exams/view")
            }
        }).catch((e: any )=>{
            toast.error(e.response.data.message)
        })
    }


    return (
        <>
            <Container className="xcn-conatiner">
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <span className="text-primary fw-bold fs-5">
                            Create New Exam
                        </span>

                    </div>
                    <Row>
                        
                        <Col>
                            <InputBox
                                label="Exam Name"
                                name="examName"
                                type="text"
                                onChange={handleInputChange}
                            />
                        </Col>
                        {/* <Col>
                            <InputBox
                                label="Start Date"
                                name="startDate"
                                type="date"
                                onChange={handleInputChange}

                            />
                            <DateRange
                            editableDateInputs={true}
                            onChange={handleInputChange}
                            moveRangeOnFirstSelection={false}
                            />
                        </Col> */}
                        {/* <Col>
                        <InputBox
                                label="End Date"
                                name="endDate"
                                type="date"
                                onChange={handleInputChange}
                            />
                        </Col> */}

                        <Col>
                        <div style = {{marginTop: '-11px'}}>

                            <Form.Label className="fw-bold xcn-text-12 text-secondary">
                                Start Date - End Date
                            </Form.Label>

                            <div className="d-flex justify-content-between align-items-center">
                                <DateTime onChange={ (e:any) => handleDateChange('startDate', e)}/>  
                                <div className="fw-bold mx-3"> - </div>
                                <DateTime onChange={ (e:any) => handleDateChange('endDate', e)} />
                            </div>
                        </div>
                        </Col>
                    </Row>
                    <div className ="d-flex justify-content-between align-items-center mt-3">
                    <XcnButton 
                        onClick={()=>onAddExamClick()}
                        text={
                            <>
                                Create New Exam
                            </>
                        }
                        variant="primary"
                        size="sm"
                    />
                    </div>
                    
                   
                </Card>
            </Container>
        </>
    )
}
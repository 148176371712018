import { faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ViewCentersTable from '../../../components/Centers/ViewCenters.table';
import AddCenterModal from '../../../components/Modals/AddCenter.modal';
import { createDateFormat } from '../../../helpers/time.helper';
import CorpService from '../../../services/corp.service';
import Select from "react-select";


export default function ViewCorpCenters() {

  const [corpCenters, setCorpCenters] = useState<any[]>([]);
  const [search, setSearch] = useState<any>("");
  const [show, setShow] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<any>({ label: "10", value: 10 });


  const params: any = useParams();


  const getCorpsUser = async () => {
    await CorpService.allCorps(params.corpId, search, pageNumber, pageCount.value).then((res) => {
      if (res.status === 200) {
        setCorpCenters(res.data);
      }
    })
  }

  useEffect(() => {
    getCorpsUser();
  }, [search, pageCount, pageNumber])



  return (
    <>
      <AddCenterModal
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        corpId={params.corpId}
        reload={getCorpsUser}
      />

      <Card className="xcn-card mt-3 ms-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <span className="text-primary fw-bold fs-5">
            Registered Centers
          </span>
          <div className="d-flex justify-content-between align-items-center">
            <div className="me-2">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search by State, City and Corp"
                  onChange={(e: any) => {
                    setSearch(e.target.value);
                    getCorpsUser();
                  }}
                />
              </Form.Group>
            </div>
            <div>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setShow(true)}
              >
                Add New Center <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </div>
        </div>

        <ViewCentersTable centers={corpCenters} reload={getCorpsUser} />


        <div className="d-flex justify-content-between align-items-center mb-3">
          <Form.Label className="fw-bold text-secondary fs-12">
            Showing Page {pageNumber} of {totalPages}
          </Form.Label>
          <div className="d-flex justify-content-between align-items-center mb-3 gap-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Page Count
            </Form.Label>
            <Select
              options={[
                { label: "10", value: 10 },
                { label: "20", value: 20 },
                { label: "50", value: 50 },
                { label: "100", value: 100 },
                { label: "150", value: 150 },
                { label: "200", value: 200 },
                { label: "250", value: 250 },
                { label: "300", value: 300 },
                { label: "350", value: 350 },
              ]}
              value={pageCount}
              onChange={setPageCount}
            />
          </div>
        </div>
      </Card>
    </>
  );
}
import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CenterApprovals from '../../../../components/Centers/CenterApprovals';
import AddApprovalModal from '../../../../components/Modals/AddApproval.modal';
import CenterService from '../../../../services/center.service';


export default function CenterApprovalInfo() {

    const params: any = useParams();

    const [approvalData, setApprovalData] = useState<any>();
    const [showApprovedModal, setShowApprovedModal] = useState<boolean>(false)

    console.log(approvalData, "approvalValidUpto")

    const getApprovalsData = async () => {
        await CenterService.getApprovalsByCenterId(params.centerId).then((res) => {
            if (res.status === 200) {
                setApprovalData(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getApprovalsData();
    }, [])

    return (
        <>
            <Container className="xcn-container">
                <AddApprovalModal
                    show={showApprovedModal}
                    setShow={setShowApprovedModal}
                    centerId={params.centerId}
                    reload={getApprovalsData}
                />
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="text-secondary fw-bold">
                            Audit Center Approvals
                        </h5>
                        <Button onClick={() => { setShowApprovedModal(true) }}>Add Approvals</Button>
                    </div>
                    <CenterApprovals
                        approvals={approvalData && approvalData.approvalData}
                        approvalValidUpto={approvalData && approvalData.approvalValidUpto}
                        reload={getApprovalsData}
                    />
                </Card>
            </Container>
        </>
    )
}
import { faEllipsisVertical, faEye, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BooleanStatus from '../../../components/status/BooleanStatus';
import { createDateFormat } from '../../../helpers/time.helper';
import { AdminService } from '../../../services/admin.service';
import CorpService from '../../../services/corp.service';
import CustomToggle from '../../../components/Menu/CustomMenu';
import { toast } from 'react-toastify';

interface IViewAllKeys {
    reload: any
}

export default function ViewAllKeys(props: IViewAllKeys) {

    const [apiKeys, setApiKeys] = useState<any[]>([])

    const navigate = useNavigate();

    const getAllApiKeys = async () => {
        await AdminService.getAllApiKeys().then(res => {
            console.log(res, 'res ');

            if (res.status === 200) {
                setApiKeys(res.data)
            }
        })
    }

    const toggleKey = async (id: string, status:boolean) => {
        await AdminService.toggleApiKeyStatus(id, status).then(res => {
            if (res.status === 200) {
                getAllApiKeys();
            }
        })
    }

    const deleteKey = async (id: string) => {
        await AdminService.deleteKey(id).then(res => {
            if(res.status == 200){
                toast.success("Deleted successfully")
                getAllApiKeys();
            }
        })
    }

    useEffect(() => {
        getAllApiKeys();
    }, [props.reload])
    return (
        <Card className="xcn-card mt-4">
            <Table borderless striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Api Key</th>
                        <th>Valid Upto</th>
                        <th>CORP/CENTER</th>
                        <th>Role Type</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {apiKeys.length > 0 ?
                        apiKeys.map((data: any, index: number) => {
                            return (
                                <tr className="xcn-table-tr" key={data._id}>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td className="fw-bold xcn-text-12">
                                        {data.apiKey}
                                    </td>
                                    <td className="text-secondary">
                                        {createDateFormat(data.validUpto)}
                                    </td>
                                    <td className='text-secondary'>
                                        {data.corpId && data.corpId.orgName} 
                                        {data.centerId && data.centerId.centerId}
                                    </td>
                                    <td>
                                        {data.role || "---"}
                                    </td>
                                    <td>
                                    <BooleanStatus
                                                status={data.isActive}
                                                value={{
                                                    true: "Active",
                                                    false: "In Active"
                                                }}
                                            />
                                    </td>
                                    <td>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                          as={CustomToggle}
                                          id="dropdown-custom-components"
                                        >
                                          <FontAwesomeIcon
                                            icon={faEllipsisVertical}
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item onClick={() => deleteKey(data._id)}>
                                            <FontAwesomeIcon
                                              icon={faTrash}
                                              className="text-info xcn-link-pointer"
                                            />
                                            <span className="fw-bold text-secondary fs-12 ms-2">
                                              Delete
                                            </span>
                                          </Dropdown.Item>
                                          <Dropdown.Item onClick={() => toggleKey(data._id, !data.isActive)}>
                                            <FontAwesomeIcon
                                              icon={faRefresh}
                                              className="text-info xcn-link-pointer"
                                            />
                                            <span className="fw-bold text-secondary fs-12 ms-2">
                                              Toggle
                                            </span>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                </tr>
                            )
                        })

                        : <tr>
                            <td colSpan={5}>
                                No Api Key found
                            </td>
                        </tr>}
                </tbody>
            </Table>
        </Card>
    )
}
import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Button,
  Image,
  Card,
  Container,
  Alert,
  Badge,
} from "react-bootstrap";
import {
  faAngleRight, faCheck, faCheckCircle, faDownload, faEye, faPencil, faSave, faTimesCircle,
  // faFileUpload,
  // faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "axios";
import { UploadService } from "../../../../services/upload.service";
import { toast } from "react-toastify";
import MyUploader from "../../../../components/Dropzone/dropzone";
import CenterService from "../../../../services/center.service";
import PreviewModal from "../../../../components/Modals/Preview.modal";
import { useParams } from "react-router-dom";
import ExtensionHelper from "../../../../helpers/ExtensionHelper";


interface ICenterDocument {
  gstCerifiacte: string,
  organizationRegistrationCertificate: string,
  aadharFront: string,
  aadharBack: string,
  pan: string,
  cancelChecque: string,
  centerAgreement: string,
}




function CenterDocument() {

  const params: any = useParams();

  const [showPreviewModal, setPreviewModal] = useState<any>(undefined);
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [centerDocumentData, setCenterDocumentData] = useState<ICenterDocument>({
    gstCerifiacte: "",
    organizationRegistrationCertificate: "",
    aadharFront: "",
    aadharBack: "",
    pan: "",
    cancelChecque: "",
    centerAgreement: "",
  })

  const s3Upload = async (fd: any, name: any) => {
    await UploadService.uploadFileToS3(fd).then((res: any) => {
      if (res.status === 200) {
        let centerData = res.data && res.data[0];
        setCenterDocumentData({ ...centerDocumentData, [name]: centerData })
        toast.success(`File(s) uploaded`);
      } else {
        toast.error("Something went wrong");
      }
    }).catch(e => {
      toast.error(e.response.data);
    })
  }


  const fileSelectedHandler = async (e: any, name: string) => {
    const fd = new FormData();
    // let arr=[];
    for (let i of e) {
      fd.append("upload", i);
    }
    toast.promise(async () => await s3Upload(fd, name), {
      pending: "Uploading...",
      success: "File(s) uploaded",
      error: "Something went wrong",
    })
  };

  const getCenterDetails = async () => {
    await CenterService.getCenterDetails(params.centerId)
      .then((res) => {
        console.log(res.data);
        setCenterDocumentData(res.data.centerDocument);
      })
  };

  const updateCenterData = async () => {
    await CenterService.updateCenterData(params.centerId, {
      centerDocument: centerDocumentData,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          setEditEnabled(false);
          getCenterDetails();
        }
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message}`
        );
      });
  };

  function openPDF(pdf: any) {
    window.open(pdf, "_blank");
  }


  useEffect(() => {
    getCenterDetails();
  }, [])


  const renderCertificate = (certificateName: any, filePath: any, uploadLabelName: string, isMandatory: boolean, col?: number) => {
    return (
      <Row>
        <Col md={col || 12}>
          <Card className="xcn-card mt-3">
            <Card.Body>
              <Row>
                <Col md={3}>
                  <div className="fw-bold h6 text-secondary d-flex justify-content-start align-items-center">
                    <div>
                      {certificateName}
                    </div>
                    <div>
                      {isMandatory && <Badge bg="danger" className="ms-1 xcn-text-8">required</Badge>}
                    </div>
                  </div>


                </Col>
                <Col md={5}>
                  <h3 className="text-primary text-decoration-none xcn-text-12">
                    {
                      filePath && <div className="d-flex justify-content-start align-items-center">
                        <FontAwesomeIcon icon={faCheckCircle} className="me-1 text-success" /> File Already Uploaded click here to
                        {
                          ExtensionHelper.isPdf(filePath) ?
                            <div className="ms-1">
                              <span className="text-success text-decoration-none xcn-link-pointer" onClick={() => openPDF(filePath)}>View/Download <FontAwesomeIcon icon={faDownload} /></span>
                            </div> :
                            <div className="ms-1">
                              <a className=" text-success text-decoration-none xcn-link-pointer" href={filePath} download>View/Download <FontAwesomeIcon icon={faDownload} /></a>
                            </div>
                        }
                      </div>
                    }
                  </h3>
                  <MyUploader
                    fileSelectedHandler={(e: any) =>
                      fileSelectedHandler(e, uploadLabelName)
                    }
                  />
                </Col>
                <Col md={4}>
                  <Alert variant="warning" className="mt-2">
                    <div className="xcn-text-10 fw-bold">
                      Upload Instructions:
                    </div>
                    <div className="xcn-text-10">
                      1. Max allowed size is 5MB - PDF/IMAGE only
                    </div>
                    <div className="xcn-text-10">
                      2. Background of the Image should be white
                    </div>
                  </Alert>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }


  return (
    <div>
      <PreviewModal
        show={showPreviewModal}
        setShow={setPreviewModal}
      />

      <Container className="">
        <>
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              variant="primary"
              className="my-4"
              onClick={updateCenterData}
            >
              {"Save"} <FontAwesomeIcon icon={faSave} />
            </Button>
          </div>
          {renderCertificate("GST Certificate", centerDocumentData?.gstCerifiacte, "gstCerifiacte", false)}
          {renderCertificate("Organization Registration Certificate", centerDocumentData?.organizationRegistrationCertificate, "organizationRegistrationCertificate", false)}
          {renderCertificate("Aadhar Copy Upload - Front", centerDocumentData?.aadharFront, "aadharFront", false)}
          {renderCertificate("Aadhar Copy Upload - Back", centerDocumentData?.aadharBack, "aadharBack", true)}
          {renderCertificate("Valid Pan Card Upload", centerDocumentData?.pan, "pan", true)}
          {renderCertificate("Cancelled Cheque", centerDocumentData?.cancelChecque, "cancelChecque", true)}
          {renderCertificate("Center Agreement", centerDocumentData?.centerAgreement, "centerAgreement", true)}

        </>
      </Container >
    </div >
  );
}

export default CenterDocument;

import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import TopBar from '../../../components/Navbars/TopBar';
import XcnComponent from '../../../components/XcnComponent/XcnComponent';
import examMappingRoutes, { examMappingBaseUrl } from '../../../router/routes/examDash/examMapping.routes';

export default function ExamMappingIndex(){
    const params: any = useParams();
    
    return (
        <>
          <TopBar
            routes={examMappingRoutes}
            baseUrl={examMappingBaseUrl(params.examId)}
            navUrlPos={3}
          />

          <XcnComponent 
            routes={examMappingRoutes}
            baseUrl={examMappingBaseUrl(params.examId)}
            navUrlPos={3}
          />
            <Outlet/>
        </>
    )
}
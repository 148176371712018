import { faCircleRadiation, faEdit, faEllipsisVertical, faEye, faShare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Badge, Dropdown, Table, ToggleButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CenterService from '../../services/center.service';
import CorpService from '../../services/corp.service';
import CustomToggle from '../Menu/CustomMenu';
import CenterStatusModal from '../Modals/CenterStatusModal';
import ResetPasswordModal from '../Modals/ResetPassword.modal';

interface IViewCentersTable {
  centers: any[];
  reload?: any

}

export default function ViewCentersTable(props: IViewCentersTable) {

  const navigate = useNavigate();
  const [modalData, setModalData] = useState<any>(undefined);
  const [showPasswordModal, setShowPasswordModal] = useState<any>(undefined);

  const handleDelete = async (id: string) => {
    await CorpService.removeCorpUser(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Removed successfully");
          props.reload();
        }
      })
      .catch((err) => {
        toast.error("Unable to remove");
      });
  };

  const resetPassword = async (centerId: any) => {
    await CenterService.resetPassword(centerId).then(res => {
      if (res.status === 200) {
        setShowPasswordModal(res.data.password)
      }
    })
  }

  return (
    <div>
      <Table borderless striped>
        <thead>
          <tr>
            <th>#</th>
            <th>Center Name</th>
            <th>Center Id</th>
            <th>Email</th>
            <th>State</th>
            <th>City</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.centers ? (
            props.centers.map((data: any, index: number) => {
              return (
                <tr className={"xcn-table-tr"}>
                  <td>{index + 1}</td>
                  <td className="fw-bold xcn-text-12">{data?.name}</td>
                  <td className="fw-bold text-danger">{data?.centerId}</td>
                  <td className="font-monospace xcn-text-12">
                    {data?.centerEmail}
                  </td>
                  <td className="font-monospace xcn-text-12">
                    {data?.districtId?.state}
                  </td>
                  <td className="font-monospace xcn-text-12">
                    {data?.districtId?.district}
                  </td>
                  <td>
                    <Badge bg={data.isActive ? "primary" : "secondary"}>{data.isActive ? "Active" : "In Active"}</Badge>
                  </td>
                  <td className="text-center">
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            navigate(
                              "/centerdash/" +
                              data?._id +
                              "/center-details/detail"
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className="text-info xcn-link-pointer"
                          />
                          <span className="fw-bold text-secondary fs-12 ms-2">
                            View
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setModalData(data);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleRadiation}
                            className="text-info xcn-link-pointer"
                          />
                          <span
                            className="fw-bold text-secondary fs-12 ms-2"
                          >
                            Active/Inactive
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => resetPassword(data?._id)}>
                          <FontAwesomeIcon
                            icon={faShare}
                            className="text-info xcn-link-pointer"
                          />
                          <span className="fw-bold text-secondary fs-12 ms-2">
                            Reset Password
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            handleDelete(data?._id);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="text-danger xcn-link-pointer"
                          />
                          <span className="fw-bold text-secondary fs-12 ms-2">
                            Delete
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5} className="text-danger fw-bold">
                No Centers Registered
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <CenterStatusModal show={modalData} handleClose={() => setModalData(false)} reload={props.reload} />

      {/* <EditCorpsCenterModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
      /> */}

      <ResetPasswordModal
        show={showPasswordModal}
        setShow={setShowPasswordModal}
      />
    </div>
  );
}